import React, { useEffect, useState } from 'react';
import banner from "../../Images/banner.webp";
import { Link } from 'react-router-dom';
import HomeService from '../../Service/HomeService';
import { toast } from 'react-hot-toast';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";


const BannerHome = () => {

    const [Banner , setBanner] = useState({});
    const [isloading , setIsLoading] = useState(true);
    const [searchValue , setSearchValue] = useState('');
    const navigate = useNavigate();

     useEffect(() => {
        HomeService.ViewBanner()
        .then((res) => {
            if(res && res?.status){
                
                console.log("AllBannerList" , res);
                setBanner(res?.data);
                setIsLoading(false)
            }else{
              toast.error(res?.message);
              setIsLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
        })
    },[])

    const HandleSearchClick = (e) => {
        e.preventDefault()

        let data = {
            searchTag: searchValue
        }
if(searchValue.trim() !==""){
    navigate('/search-collection/'+searchValue,);

}
        
        // HomeService.SearhSection(data)
        // .then((res) => {
        //     console.log("RESPONSE",res);
        //     if(res && res?.status){
        //         if(res?.data.length === 0){
        //             toast.error("NO Collection Matched")
        //             setSearchValue("");
        //         }else{
        //             console.log("SEARCHDATA" , res?.data);
        //         }
            
        //     }else{
        //       toast.error(res?.message);
             
        //     }
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
    }
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return (
        <div>
            {
                isloading ? <Loader /> :  <section className='banner' style={{ backgroundImage: `url('${Banner?.image}')`, position: 'relative' }}>
                <div className='home_courtesy'>Photo credit: <span>Qhevika Swu</span></div>
                <div className='nagacontainer'>
                    <div className='bannerWrap'>
                        <div className='leftCnt' data-aos="fade-up" data-aos-duration="3000">
                            <div>
                                <h1><span>{Banner?.heading1}</span>
                                    <span className='pp'>{Banner?.heading2}</span>
                                </h1>
                                <form className='myInpDiv' onSubmit={HandleSearchClick}>
                                    <div className='myInput'>
                                        <input type="text"  value={searchValue} onChange={(e) => setSearchValue(e.target.value)}  placeholder='Search the collections'></input>
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                    <button type='submit' className='redbg' onClick={HandleSearchClick}>SEARCH <strong><i class="fa-solid fa-chevron-right"></i></strong></button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            }
               
           

        </div>
    )
}

export default BannerHome
