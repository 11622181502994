import React from "react";
import prof from "../../Images/user.jpg";

function CommentModal(props) {
  const { closemodal, setComment, comment, handleComment, commentData } = props;

  console.log("Commentsgftrtrrtrtrt", commentData);

  return (
    <>
      <section className="commentmodal_area">
        <div className="content_wrap">
          <div className="close_btn">
            <button className="btn" onClick={() => closemodal(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          {commentData ? (
            <div className="content_body">
              {commentData &&
                commentData?.map((item, i) => {
                  return (
                    <div className="otherscomment" key={i}>
                      {/* <div className='profile'>
                                      <img src={prof} className='img-fluid' alt='' />
                                  </div> */}
                      <div className="details">
                        <div className="name">
                          <h4>{item?.user?.name}</h4>
                        </div>
                        <div className="text">
                          <p>{item?.comments}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className="givcomment_bar">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby="textHelp"
                    placeholder="Write a comment....."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="send_btn"
                    onClick={handleComment}
                  >
                    <i className="fa-regular fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* <p style={{ height:'23px'}}> No Data Found</p> */}
              {/* <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%",
                }}
                src="https://assets-v2.lottiefiles.com/a/0e30b444-117c-11ee-9b0d-0fd3804d46cd/BkQxD7wtnZ.gif"
              /> */}

              <div style={{ textAlign: "center", marginTop: "12px" }}>
                No Comment(s) Yet
              </div>
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  height: "100px",
                }}
              >
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby="textHelp"
                    placeholder="Write a comment....."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="send_btn"
                    onClick={handleComment}
                  >
                    <i className="fa-regular fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default CommentModal;
