import React from 'react'
import a from "../../Images/news.jpg"
export default function Index() {
  return (
    <>

      <section id="news">
        <div className="container">
          <div className="news_body">
            <div className="news_top">
              <h1 className="tophead">News &amp; Updates</h1>
            </div>
            <div className="news_btm">
              <div className="news_list">
                <div className="news_img">
                  <img src={a} alt="img" />
                </div>
                <div className="news_text">
                  <h3 className="newshead">
                    AI generated content should be labelled, EU Commissioner Vera
                    Jourova says
                  </h3>
                  <p className="newspara">
                    Companies such as Google, Microsoft and Meta Platforms that have
                    signed up to the EU Code of Practice to tackle disinformation
                    should report on safeguards put in place to tackle this in July,
                    Jourova said.
                  </p>
                  <p className="date">05 Jun, 2023, 07:14 PM IST</p>
                </div>
              </div>
              <hr />
              <div className="news_list">
                <div className="news_img">
                  <img src={a} alt="img" />
                </div>
                <div className="news_text">
                  <h3 className="newshead">
                    AI generated content should be labelled, EU Commissioner Vera
                    Jourova says
                  </h3>
                  <p className="newspara">
                    Companies such as Google, Microsoft and Meta Platforms that have
                    signed up to the EU Code of Practice to tackle disinformation
                    should report on safeguards put in place to tackle this in July,
                    Jourova said.
                  </p>
                  <p className="date">05 Jun, 2023, 07:14 PM IST</p>
                </div>
              </div>
              <hr />
              <div className="news_list">
                <div className="news_img">
                  <img src={a} alt="img" />
                </div>
                <div className="news_text">
                  <h3 className="newshead">
                    AI generated content should be labelled, EU Commissioner Vera
                    Jourova says
                  </h3>
                  <p className="newspara">
                    Companies such as Google, Microsoft and Meta Platforms that have
                    signed up to the EU Code of Practice to tackle disinformation
                    should report on safeguards put in place to tackle this in July,
                    Jourova said.
                  </p>
                  <p className="date">05 Jun, 2023, 07:14 PM IST</p>
                </div>
              </div>
              <hr />
              <div className="news_list">
                <div className="news_img">
                  <img src={a} alt="img" />
                </div>
                <div className="news_text">
                  <h3 className="newshead">
                    AI generated content should be labelled, EU Commissioner Vera
                    Jourova says
                  </h3>
                  <p className="newspara">
                    Companies such as Google, Microsoft and Meta Platforms that have
                    signed up to the EU Code of Practice to tackle disinformation
                    should report on safeguards put in place to tackle this in July,
                    Jourova said.
                  </p>
                  <p className="date">05 Jun, 2023, 07:14 PM IST</p>
                </div>
              </div>
              <hr />
              <div className="news_list">
                <div className="news_img">
                  <img src={a} alt="img" />
                </div>
                <div className="news_text">
                  <h3 className="newshead">
                    AI generated content should be labelled, EU Commissioner Vera
                    Jourova says
                  </h3>
                  <p className="newspara">
                    Companies such as Google, Microsoft and Meta Platforms that have
                    signed up to the EU Code of Practice to tackle disinformation
                    should report on safeguards put in place to tackle this in July,
                    Jourova said.
                  </p>
                  <p className="date">05 Jun, 2023, 07:14 PM IST</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
