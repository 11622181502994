import React, { useEffect, useMemo, useState } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/reducers/ProfileReducer";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";

const userContext = React.createContext(null);

export const useUserData = () => React.useContext(userContext);

const UserContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer);

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      // text: "Do you really want to log out ?",
      icon: "warning",
      showCancelButton: true,
      // showNoButton:true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        reactLocalStorage.clear();
        dispatch(getProfile());
        toast.success("Logged Out Successfully");
      }
    });
  };

  const isLogin = useMemo(() => {
    let token = localStorage.getItem("token");
    if (token) return true;
    else return false;
  }, [profile]);

  // const isLogin = localStorage.getItem("token") ? true : false;
  console.log("isLogin", isLogin);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <userContext.Provider
      value={{
        logout,
        isLogin,
      }}
    >
      {children}
    </userContext.Provider>
  );
};
export default UserContextProvider;
