import { async } from "q"
import HttpClient from "../utils/HttpClient"

async function viewcollectioncat(id, data) {
    let endpoint = 'view-collection-category-type/' + id
    return HttpClient.post(endpoint, data)
}
async function thematicData() {
    let endpoint = "view-all-thematic"
    return HttpClient.get(endpoint)
}
async function viewParticularThematic(id) {
    let endpoint = "view-particular-thematic/" + id
    return HttpClient.get(endpoint)
}
async function allPhotoCategory(id) {
    let endpoint = "view-photo-category/" + id
    return HttpClient.get(endpoint)
}
async function viewPartcularGallery(id) {
    let endpoint = "view-particular-gallery/" + id
    return HttpClient.get(endpoint)

}
async function thematicCollection() {
    let endpoint = "view-all-thematic"
    return HttpClient.get(endpoint)
}
async function featureCollection() {
    let endpoint = "view-feature-collection"
    return HttpClient.get(endpoint)
}

async function userRegister(data) {
    let endpoint = "user-register";
    return HttpClient.post(endpoint, data)
}

async function userLigin(data) {
    let endpoint = "login";
    return HttpClient.post(endpoint, data)
}

async function viewProfile() {
    let endpoint = "view-profile"
    return HttpClient.get(endpoint)
}

async function viewFeature() {
    let endpoint = "view-feature-collection"
    return HttpClient.get(endpoint)
}

async function viewColCat() {
    let endpoint = "view-all-category"
    return HttpClient.get(endpoint)
}

async function viewParticularCollection(id) {
    let endpoint = "view-particular-collection-by-params/" + id
    return HttpClient.get(endpoint)
}

async function viewAllBlogs() {
    let endpoint = "view-all-blog"
    return HttpClient.get(endpoint)
}

async function viewParticularBlog(id) {
    let endpoint = "view-particular-blog/" + id
    return HttpClient.get(endpoint)
}

async function addUserComment(data) {
    let endpoint = "add-user-comments";
    return HttpClient.post(endpoint, data)
}

async function viewComments(id) {
    let endpoint = `view-comments/${id}`
    return HttpClient.get(endpoint)
}

async function viewPhotoCatByThemeId(id) {
    let endpoint = "view-photo-collection-by-themeId/" + id
    return HttpClient.get(endpoint)
}

async function changePassword(data) {
    let endpoint = "change-password";
    return HttpClient.put(endpoint, data)
}

async function ViewBanner() {
    let endpoint = 'view-banner';
    return HttpClient.get(endpoint)
}

async function SearhSection(data){
    let endpoint = 'search-tag';
    return HttpClient.post(endpoint , data);
}



async function ViewStartupBody(id , data){
    let endpoint = `view-startup-edu-by-themeslug/${id}`;
    return HttpClient.post(endpoint , data)
}

async function ViewAllEducationStartupType(id){
    let endpoint = `category-type/${id}`;
    return HttpClient.get(endpoint);
}

async function ViewOurContributingPartnersLogo(){
    let endpoint = 'logo';
    return HttpClient.get(endpoint)
}

async function ViewFolklore() {
    let endpoint = 'folklore-get';
    return HttpClient.get(endpoint)
}


 ///                 DESCRIPTION


 async function ViewCollectionDesc() {
    let endpoint = 'view-user-collectiondesc';
    return HttpClient.get(endpoint)
}
async function ViewThematicDesc() {
    let endpoint = 'view-user-thematicdesc';
    return HttpClient.get(endpoint)
}
async function ViewBlogDesc() {
    let endpoint = 'view-user-blogdesc';
    return HttpClient.get(endpoint)
}
async function ViewFlokloreDesc() {
    let endpoint = 'view-user-flokdesc';
    return HttpClient.get(endpoint)
}

async function getFolkloreColl(id) {
    let endpoint = `floklore-collection/${id}`;
    return HttpClient.get(endpoint)
}

export default {
    viewcollectioncat,
    getFolkloreColl,
    ViewBlogDesc,
    ViewFlokloreDesc,
    ViewThematicDesc,
    ViewCollectionDesc,
    thematicData,
    viewParticularThematic,
    allPhotoCategory,
    viewPartcularGallery,
    thematicCollection,
    featureCollection,
    userRegister,
    userLigin,
    viewProfile,
    viewFeature,
    viewColCat,
    viewParticularCollection,
    viewAllBlogs,
    viewParticularBlog,
    addUserComment,
    viewComments,
    viewPhotoCatByThemeId,
    changePassword,
    ViewBanner,
    SearhSection,
    ViewStartupBody,
    ViewAllEducationStartupType,
    ViewOurContributingPartnersLogo,
    ViewFolklore,
}
