import React from "react";

export default function Index() {
  return (
    <section className="privacy-area terms-area section-tb-padding my-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="terms-title">
              <h1>Privacy policy</h1>
            </div>
            <p className="mb-3">
              <strong >Introduction:</strong>{" "}
              Welcome to the Digital Library of Nagaland's Privacy Policy. We
              respect your privacy and are committed to protecting your personal
              information. This policy explains how we collect, use, and
              safeguard your data when you use our website and services.
            </p>
            <div className="privacy-content">
              <div className="mb-3">
                <h5 className="mb-3">Information We Collect:</h5>
                <ul className="privacy-policy">
                  <li>
                    <p className="priVacyP">
                      Personal Information: We may collect personal information,
                      such as your name, email address, and contact details when
                      you register for an account or subscribe to our services.
                    </p>
                  </li>
                  <li>
                    <p className="priVacyP">
                      Usage Information: We may gather information about your
                      interactions with our website, including your browsing
                      history and the content you access.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="mb-3">
                <p className="mb-3">
                  <strong >
                    How We Use Your Information:
                  </strong>{" "}
                  We use the collected information to:
                </p>
                <ul className="privacy-policy">
                  <li>
                    <p className="priVacyP">
                      Provide and improve our services.
                    </p>
                  </li>
                  <li>
                    <p className="priVacyP">
                      Send you updates, newsletters, and other relevant content.
                    </p>
                  </li>
                  <li>
                    <p className="priVacyP">
                      Ensure the security and integrity of our platform.
                    </p>
                  </li>
                  <li>
                    <p className="priVacyP">Comply with legal obligations.</p>
                  </li>
                </ul>
              </div>
              <p className="mb-3">
              <strong >Sharing Your Information:</strong>{" "}
              We do not sell or share your personal information with third parties, except when required by law or with your explicit consent.
            </p>

            <p className="mb-3">
              <strong >Your Choices:</strong>{" "}
              You can control your information and communication preferences by updating your account settings or unsubscribing from our communications.
            </p>
            <p className="mb-3">
              <strong >Security Measures:</strong>{" "}
              We employ security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.
            </p>
            <p className="mb-3">
              <strong >Changes to this Privacy Policy:</strong>{" "}
              We may update this Privacy Policy to reflect changes in our practices. Please review this policy periodically.
            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
