import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HomeService from "../../Service/HomeService"


const initialState = {
    profileData: [],
    loading: false,
    error: ""
}

export const getProfile = createAsyncThunk("profile/getProfile", async () => {
    const res = await HomeService.viewProfile();
    console.log("resGetprofile", res)
    return res
})

const profileSlice = createSlice({
    name: "getProfile",
    initialState,
    reducers: {},
    extraReducers: {
        [getProfile.pending]: (state) => {
            state.loading = true;
        },
        [getProfile.fulfilled]: (state, action) => {
            if (action.payload.status) {
                state.profileData = action.payload.data;
                state.loading = false;
            } else {
                state.profileData = []
                state.loading = false;
                state.error = action.payload.message
            }
        },
        [getProfile.rejected]: (state) => {
            state.loading = false;
        }
    }
})

export const profileReducer = profileSlice.reducer;