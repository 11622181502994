import React, { useEffect } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import pattern from "../../Images/pattern.jpg"
import naga from "../../Images/nagalandnature.jpg"
import AOS from "aos";
import "aos/dist/aos.css";


const ContactHome = () => {
    // aos animation
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div>
            <section className='contactus_sec'>
                <div className='nagacontainer'>
                    <div className='collectionTop'>
                        {/* <p className='homeFea'>Home to Local Collections, Indigenous Literature, art and many more.</p> */}
                        {/* <figure className='ptrnFig'>
                            <img src={pattern} alt='' />
                        </figure> */}
                    </div>
                    <div className='row mt-4'>
                        <div className='col-lg-7 col-md-7 col-12'>
                            <div className='leftara' style={{ maxWidth: "600px" }} 
                            // data-aos="fade-up"
                            //  data-aos-duration="3000"
                             >
                                <h3 className="feco">Contact us if you have any questions</h3>

                                <p className='text_oo'>Write to us if you have any questions, feedback or would like to contribute to our collections.</p>

                                <div className='seemore_div mt-5' style={{ justifyContent: "start" }}>
                                    <Link to="/contact">Contact</Link>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-5 col-md-5 col-12'>
                            <figure className='rightimg' data-aos="fade-up" data-aos-duration="3000">
                                <img src={naga} className="img-fluid" alt='nagaland' />
                               
                            </figure>
                            <p style={{fontSize:'10px',fontFamily: 'Montserrat'}}>Photo Credit:<span> Vipin Joseph/unsplash</span></p>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default ContactHome
