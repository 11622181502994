import React, { useEffect, useState } from 'react';
import "./angami.css"
import HttpClient from '../../utils/HttpClient';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTribe } from '../../redux/reducers/FolkLoreSlice';
function DropDown({ tribeId }) {
  const { tribeData } = useSelector(s => s.folkLoreRed)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState(false);

  // const [allTribes, setAllTribes] = useState({});

  console.log("tribeDatarr", tribeData)


  const handelOnClick = () => {
    setTimeout(() => {
      if (state === true) {
        setState(false);
      } else {
        setState(true);
      }
    }, 200);
  };



  // get single folklore
  // const getFolkVolumeSingle = async () => {
  //   const res = await HttpClient.requestData("folklore-get", {}, "GET")
  //   if (res && res?.status) {
  //     setAllTribes(res?.data)
  //   } else {
  //     console.log(res?.message)
  //   }
  // }

  useEffect(() => {
    // getFolkVolumeSingle();
    dispatch(getAllTribe())
  }, [])



  return (
    <>
      <div onClick={handelOnClick} className="left-title-box">
        <div>
          {/* <i class="fa-regular fa-square"></i> */}
          <p className="title-headind">Folklores by Tribes</p>
        </div>
        <i
          className={
            state ? `fa-solid fa-angle-up` : `fa-solid fa-angle-down`
          }
        ></i>
      </div>


      {state && (
        <div className="left-list-box">

          {
            tribeData?.map((item, i) =>
              <li
                key={i}
                className={`box-lists ${tribeId === item?._id ? "activeDropTrive" : ""}`}
                onClick={() => navigate("/single-tribe/" + item?._id)}
              >
                <p className="list-name">{item?.name}</p>
                <p className="list-number">{item?.folkLoreCollections?.length}</p>
              </li>
            )
          }


          {/* <li className="box-lists">
            <p className="list-name">Tribe Name</p>
            <p className="list-number">4</p>
          </li>
          <li className="box-lists">
            <p className="list-name">Tribe Name</p>
            <p className="list-number">2</p>
          </li>
          <li className="box-lists last-li">
            <p className="list-name">Tribe Name</p>
            <p className="list-number">2</p>
          </li> */}
        </div>
      )}
    </>
  )
}

export default DropDown
