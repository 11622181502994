import React from 'react';
import './App.css';
import "./View/Athentic/Login.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "../src/View/Home/Index"
import MainLayout from './Layout/MainLayout';
import Collection from '../src/View/Collection/Index'
import CollectionLayout from './Layout/CollectionLayout';
import CategoryDetails from './View/Collection/CategoryDetails';
import TypesDetails from './View/Collection/TypesDetails';
import Blog from "../src/View/Blog/Index"
import Partner from "../src/View/Partner/Index";
import BlogDetails from "../src/View/Blog/Details";
import Privacy from "../src/View/Privacy/Index";
import Faq from "../src/View/FAQ/Index";
import Terms from "../src/View/TermsCondition/Index";
import Contact from "../src/View/Contact/Index";
import Cookie from "../src/View/CookiePolicy/Index";
import News from "../src/View/NewsUpdates/Index";
import About from '../src/View/About/Index';
import MoreDetails from "../src/View/Collection/MoreDetails";
import Thematic from "../src/View/Collection/Thematic";
import ThematicDetails from "../src/View/Collection/ThematicDetails";
import Gallery from './View/Gallery/Gallery';
import StarupsNagaland from "../src/View/Collection/StarupsNagaland";
import EducationCareer from "../src/View/Collection/EducationCareer"
import { Toaster } from 'react-hot-toast';
import Login from './View/Athentic/Login';
import SignUp from './View/Athentic/SignUp';
import DocumentView from './View/Collection/DocumentView';
import GoToTop from './Component/GoToTop';
import SearchCollection from './View/Collection/SearchCollection';
import ViewDocuments from './View/Documents/ViewDocuments'
import FolklorePage from './View/Folklore/FolklorePage';
import FolkloreDetailsPage from './View/Folklore/FolkloreDetailsPage';
import Accessibility from './View/Accessibility/Index'
import PartnersPage from './View/Partner/PartnersPage';
import FolklorePageNew from './View/Folklore/FolklorePageNew';
import SingleVolume from './View/Folklore/SingleVolume';
import Angami from './View/Folklore/Angami';
import Kashopapu from './View/Folklore/Kashopapu';
function App() {
  return (
    <>
      <Router>
        <Toaster />
        <GoToTop />
        <Routes>
          <Route element={<MainLayout />}>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path="/" element={<Home />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/thematic" element={<Thematic />} />
            <Route path='/thematic-details/:id' element={<ThematicDetails />} />
            <Route path="/category-details/:id" element={<CategoryDetails />} />
            <Route path="/types-details/:id" element={<TypesDetails />} />
            {/* <Route path='/StarupsNagaland' element={<StarupsNagaland />} /> */}
            {/* <Route path='/education-career' element={<EducationCareer />} /> */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/partner" element={<Partner />} />
            <Route path='/partners-page' element={<PartnersPage />} />
            <Route path="/blog-details/:slug/:id" element={<BlogDetails />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/Accessibility" element={<Accessibility />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/cookie" element={<Cookie />} />
            <Route path="/news" element={<News />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/more-details/:id" element={<MoreDetails />} />
            <Route path="/view-collection/:id" element={<DocumentView />} />
            <Route path="/search-collection/:searchquery" element={<SearchCollection />} />

            <Route path="/view-documents" element={<ViewDocuments />} />
            <Route path='/folklore' element={<FolklorePage />} />
            <Route path='/folklore-details/:id' element={<FolkloreDetailsPage />} />


            <Route path='/folklore-new' element={<FolklorePageNew />} />
            <Route path='/folklore-Volume/:id' element={<SingleVolume />} />
            <Route path='/single-tribe/:id' element={<Angami />} />
            <Route path='/folklore-collection/:id' element={<Kashopapu />} />
          </Route>
          <Route element={<CollectionLayout />}></Route>
        </Routes>

      </Router>
    </>
  );
}

export default App;
