import React, { useEffect, useState } from "react";
import "./fluk.css";
import folker from "../../Images/hornbill-festival-kohima-nagaland-india.png";
import vol_img from "../../Images/vol-img.png";
import conti_img from "../../Images/naga_dadu.png";
import { Link } from "react-router-dom";
import bannImg from "../../Images/Folklore-banner-bg.png"
import bannImg2 from "../../Images/Folklore-banner-img.png"
import HttpClient from "../../utils/HttpClient";
function FolklorePageNew() {

  const insertData = [
    {
      vol_title_1: "Insert title",
      vol_title_2: "(Folklore volume 1)",
      vol_image: vol_img,
      vol_desc:
        "Suspendisse purus habitant sem dolor. Enim dui sollicitudin ornare. Duis feugiat et semper a.",
    }
  ];

  const [folkVolumeData, setFolkVolumeData] = useState([]);

  // console.log("folkVolumeData", folkVolumeData)

  // get folk volume
  const getFolkVolume = async () => {
    const res = await HttpClient.requestData("folklore-volume-all", {}, "GET")
    if (res && res?.status) {
      setFolkVolumeData(res?.data)
    } else {
      console.log(res?.message)
    }
  }

  useEffect(() => {
    getFolkVolume()
  }, [])

  return (
    <>
      {/* style={{backgroundImage: `url(${folker})`}} */}
      {/* <section className="banner-section">
        <div className="banner-img-box">
          <img className="banner-image" src={folker} alt="..." />
        </div>
        <div className="nagacontainer">
          <div className="banner-main-box">
            <div className="banner-text">
              <p className="banner-title">Folklore</p>
              <p className="banner-desc">
                Lorem ipsum dolor sit amet consectetur. Dignissim urna sit
                feugiat quis id.{" "}
              </p>
            </div>
          </div>
        </div>
      </section> */}


      <section className="folklore_banner">
        <div className="folklorebackimgwrap">
          <img src={bannImg} className="img-fluid" alt="..." />
        </div>
        <div className="nagacontainer">
          <div className="content row m-0">
            <div className="col-lg-6 col-md-6 col-12 lefttext">
                <h2 className="heading">Preserving <br /> Nagaland's Folklore</h2>
                {/* <h6 className="sub_heading">Lorem ipsum dolor sit amet consectetur. Dignissim urna sit feugiat quis id. </h6> */}
                {/* <p className="text">Nagaland's folklore is a treasure trove of wisdom, cultural identity, and historical narratives. It reflects the deep-rooted beliefs, practices, and the ever-evolving traditions of its diverse tribal communities. As Nagaland continues to modernize, preserving and sharing these rich stories is essential in maintaining the unique cultural heritage of the region.</p> */}
                {/* <p className="text">Explore the wisdom, cultural identity, and historical narratives of Nagaland's diverse tribal communities. Discover the rich stories that reflect the deep-rooted beliefs and ever-evolving traditions of the region.</p> */}
              
                <p className="text">Nagaland boasts a vibrant oral tradition that serves as a cornerstone of its cultural legacy, preserving age-old customs and beliefs. Rooted in the sharing of anecdotes and tales, this tradition encapsulates the essence of Naga heritage, offering invaluable insights into the values, practices, and beliefs of diverse tribes. These stories, imbued with profound meanings, serve as timeless conduits of Naga life.</p>
                <p className="text">Dedicated to the preservation and accessibility of this rich heritage, our Digital Library collaborates with academics, authors, and organizations to curate a comprehensive collection of folktales and folklore from Nagaland's myriad tribes. Through this initiative, we aim to safeguard these cultural treasures and make them readily available to a global audience.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="bannerimg_wrap">
                <img src={bannImg2} className="img-fluid" alt="..." />
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* <section className="folk-about">
        <div className="nagacontainer">
          <div className="about-main-box">
            <p className="folk-about-title">Lorem ipsum dolor sit amet</p>
            <p className="folk-about-mini-title">
              Lorem ipsum dolor sit amet consectetur.
            </p>
            <div className="about-desc-box">
              <p className="folk-about-desc1">
                Lorem ipsum dolor sit amet consectetur. At mauris sit ultricies
                amet in congue laoreet fames mauris. Etiam nunc tincidunt
                egestas blandit bibendum augue donec cursus. Lacus in ut nibh
                aliquam tellus netus. Orci risus neque aliquam et et facilisis
                proin. Curabitur et metus eu nisi mi sagittis.
              </p>
              <p className="folk-about-desc2">
                Vitae est felis volutpat nulla molestie id consequat urna. Vel
                amet consequat in eros vel aliquet nibh gravida arcu. Purus
                volutpat in vitae ut egestas duis feugiat venenatis aliquet.
                Enim amet elementum amet tempor. Ornare rutrum ac tempus aenean
                ultrices rhoncus crash.Lorem ipsum dolor sit amet consectetur.
                At mauris sit ultricies amet in congue laoreet fames mauris.
                Etiam nunc tincidunt egestas blandit bibendum augue donec
                cursus. Lacus in ut nibh aliquam tellus netus. Orci risus neque
                aliquam et et facilisis proin. Curabitur et metus eu nisi mi
                sagittis.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      {/* folk volumes */}
      <section className="folk-volume-section">
        <div className="nagacontainer">
          <div className="folk-volume-main-box">
            {folkVolumeData.map((ele, index) => {
              return (
                <div key={index} className="volume-main-section">
                  <p className="folk-volume-title">
                    {ele.title}
                    <span className="inner-title">{ele.vol_title_2}</span>
                  </p>
                  <div className="folk-volume-img">
                    <img src={ele.image} alt="img" />
                  </div>
                  <p className="vol-desc">
                    {/* {ele.smallDesc} */}
                  </p>

                  <div className="vol-btn">
                    <Link className="" to={"/folklore-Volume/" + ele?._id} style={{ color: `#fff` }}>
                      READ MORE
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>


      <section
        className="contributor-section"
        style={{ backgroundImage: `url(${conti_img})` }}
      >
        <div className="nagacontainer">
          <div className="totalcontentwrap">
            <div className="contributor-main">
              <p className="contributor-title">Become a contributor</p>
              <p className="contributor-desc">
                Become a valued partner by sharing your creative content with us. Let's enrich our creative journey together.
              </p>

              <div className="vol-btn" style={{ justifyContent: "start" }}>
                <Link to="/contact">
                  CONTACT US &nbsp;{" "}
                  <i id="contri-btn-icon" class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="PhotoCredit_block">
          <div className="nagacontainer">
            <div className="text">
              <span>Photo Credit :</span>
              <Link target="_blank" to="https://www.tourmyindia.com/blog//wp-content/uploads/2019/04/Nagaland-Headhunter-Tribe.jpg">tourmyindia</Link>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default FolklorePageNew;
