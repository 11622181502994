import React from "react";

export default function Index() {
  return (
    <>
      <section className="terms-area section-tb-padding my-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="terms-title">
                <h1>Terms &amp; conditions</h1>
              </div>

              <div className="terms-content privacy-content">
                <p className="mb-3">
                  <strong >Introduction:</strong>{" "}
                  Welcome to the Digital Library of Nagaland's Terms and
                  Conditions. By using our website and services, you agree to
                  comply with these terms.
                </p>
                <div className="mb-3">
                  <p className="mb-3">
                    <strong style={{ fontSize: "20px" }}>User Conduct:</strong>{" "}
                    You agree to:
                  </p>
                  <ul className="privacy-policy">
                    <li>
                      <p className="priVacyP">
                        Use our services for lawful purposes only.
                      </p>
                    </li>
                    <li>
                      <p className="priVacyP">
                        Not violate any applicable laws or regulations.
                      </p>
                    </li>
                    <li>
                      <p className="priVacyP">
                        Respect the rights of others and our intellectual
                        property.
                      </p>
                    </li>
                  </ul>
                </div>
                <p className="mb-3">
                  <strong >Content Submission:</strong>{" "}
                  When you submit content to our library, you grant us a non-exclusive license to use and display that content on our platform. You must have the necessary rights to submit content.
                </p>
                <p className="mb-3">
                  <strong >Intellectual Property:</strong>{" "}
                  Our website and its content are protected by copyright and other intellectual property laws. You may not use our content without permission.
                </p>
                <p className="mb-3">
                  <strong >Disclaimer:</strong>{" "}
                  We provide our services "as is," and we do not make any warranties, express or implied, regarding the accuracy or reliability of the content.
                </p>
                <p className="mb-3">
                  <strong >Limitation of Liability:</strong>{" "}
                  We are not liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our services.
                </p>
           
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
