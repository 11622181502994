import { configureStore } from "@reduxjs/toolkit";
import { thematicReducer } from "./reducers/ThemeSlice";
import { profileReducer } from "./reducers/ProfileReducer";
import { featureReducer } from "./reducers/FeatureReducer";
import { colCatReducer } from "./reducers/CollectionCategorySlice";
import { allBlogReducer } from "./reducers/BlogSlice";
import { folkLoreRed } from './reducers/FolkLoreSlice'
export const store = configureStore({
    reducer: {
        thematicReducer,
        profileReducer,
        featureReducer,
        colCatReducer,
        allBlogReducer,
        folkLoreRed
    }
})