import React from 'react'
import g from "../Images/history1.jpg"

function HistoryLeftCard({ singleColCat }) {
    console.log("singleColCatHis", singleColCat);
    return (
        <>
            <div className="history_left">
                <div className="card left-content">
                    <div className="left_imgwrapper">
                        <img src={singleColCat?.image} className="card-img-top" alt="img" />
                    </div>

                    <div className="card-body">
                        <h5 className="card-title">{singleColCat?.name}</h5>
                        <p className="card-text">
                            {singleColCat?.desc}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HistoryLeftCard