import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import HomeService from '../../Service/HomeService';

const DocumentView = () => {
    const { id } = useParams();
    const [singleColData, setSingleColData] = useState({});
    console.log("singleColDataDoc", singleColData)

    const getSingleCol = async () => {
        const res = await HomeService.viewParticularCollection(id);

        console.log("singleReccolDoc", res);
        if (res && res?.status) {
            setSingleColData(res?.data[0]);
            // setColType(res?.data[0]?.type);
        }
    }

    useEffect(() => {
        getSingleCol();
    }, [])

    return (
        <div>
            document
            {/* <iframe
                src={singleColData?.uploadCollection}
            // width="100"
            // height="50"
            /> */}

            <object data={singleColData?.uploadCollection} type="application/pdf" width="100%" height="500px">
                {/* <p>Unable to display PDF file. <a href="/uploads/media/default/0001/01/540cb75550adf33f281f29132dddd14fded85bfc.pdf">Download</a> instead.</p> */}
            </object>
        </div>
    )
}

export default DocumentView
