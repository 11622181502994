import React, { useEffect, useState } from 'react'
// import BreadcrumbsHeader from '../../Component/BreadcrumbsHeader'
import topimg from "../../Images/bok-photo1.jpg"
import video from "../../Images/videos/Intro-Short-Video-Edinboro-University.mp4";
import aa from "../../Images/book1.jpg"
import bb from "../../Images/book2.jpg"
import cc from "../../Images/book3.jpg"
import dd from "../../Images/book4.jpg"
import ee from "../../Images/book5.jpg"
import ff from "../../Images/photo1.jpg"
import gg from "../../Images/photo2.jpg"
import hh from "../../Images/photo3.jpg"
import ii from "../../Images/doc1.jpg"
import jj from "../../Images/doc2.jpg"
import kk from "../../Images/doc3.jpg"
import ll from "../../Images/doc4.jpg"
import mm from "../../Images/doc5.jpg"
import HomeService from '../../Service/HomeService';
import { Link, useParams } from 'react-router-dom';
import PaginationTheme from './PaginationTheme';
import NoDataFound from '../../Helper/NoDataFound';
import BreadcrumbsHeader from '../../Component/BreadcrumbsHeader';
import OtherTheme from './OtherTheme';
import EducationCareer from './EducationCareer';
import StarupsNagaland from './StarupsNagaland';
import { toast } from 'react-hot-toast';

function ThematicDetails() {
    const loop = [
        { id: 1, main: "Narratives from the Battle of Kohima", descrip: "An oral narrative of those who saw and lived through the Battle of Kohima in 1944.", type: "Author: TakeOne Nagaland", },
        { id: 2, main: "Battle of Kohima 1944, WWII: A Naga Peoples' Perspective", descrip: "2019 marked 75 years of the Battle of Kohima. This documentary is produced by the Kohima Educational Trust KET, and Edited by Highland Dawn Media, Kohima, Nagaland.", type: "Author: Highland Dawn Media", },
        { id: 3, main: "The Siege of Kohima", descrip: "Some historians refer to the Defence of Kohima as Britain's Greatest Battle. Others refer to the siege as the Stalingrad of the East. The Japanese U-go offensive and subsequent battle of Kohima (and Imphal) are among the most brutal in the Pacific Theatre, and led to a surprising victory.", type: "Author: House of History", },
    ]

    const [singleThematic, setSingleThemetic] = useState({});
    const [themeBook, setThemeBook] = useState([]);
    const [themeDocs, setThemeDocs] = useState([]);
    const [themeVideo, setThemeVideo] = useState([]);
    const [photoData, setPhotoSData] = useState([]);
    const [thematicType, setThematicType] = useState("");

    const [pagiBook, setPagiBook] = useState([]);
    const [pagiDocs, setPagiDocs] = useState([]);

    const { id } = useParams();

    console.log("singleThematic", singleThematic)

    const handlePartThematic = async () => {
        console.log("ID",id);
        const res = await HomeService.viewParticularThematic(id)
        // console.log("ParticularThematicData", res)
        if (res && res.status) {
            console.log("res",res);
            setSingleThemetic(res?.data[0]);
            setThematicType(res?.data[0]?.themeType)

            let books = res.data[0]?.data.find((item) => item._id === "books")?.data;
            let document = res.data[0]?.data.find((item) => item._id === "documents")?.data;
            let video = res.data[0]?.data.find((item) => item._id === "videos")?.data;

            setThemeBook(books ? books : []);
            setThemeDocs(document ? document : []);
            setThemeVideo(video ? video : []);


        }
    }

    const getPhotoCat = async () => {
        const res = await HomeService.viewPhotoCatByThemeId(id);
        console.log("resPartPhoto", res);
        if (res && res?.status) {
            setPhotoSData(res?.data)
        }
    }

    useEffect(() => {
        handlePartThematic();
        getPhotoCat();
        
    }, [])

 

    return (
        <>

            {thematicType === "others" &&
                < OtherTheme
                    singleThematic={singleThematic}
                    photoData={photoData}
                    themeDocs={themeDocs}
                    themeVideo={themeVideo}
                    themeBook={themeBook}
                />
            }

            {
                thematicType === "education" &&
                <EducationCareer
                    singleThematic={singleThematic}
                />
            }

            {
                thematicType === "startup" &&
                <StarupsNagaland
                    singleThematic={singleThematic}
                />
            }
        </>
    )
}

export default ThematicDetails