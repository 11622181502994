import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Outlet } from 'react-router-dom'

export default function MainLayout() {
  return (
    <>

      <Header />
      <div className='mainRemain' id='main'>
        <Outlet />
      </div>
      <Footer />

    </>
  )
}
