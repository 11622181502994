import React, { useEffect } from "react";
import { useState } from "react";
import g from "../../Images/history1.jpg";
import d from "../../Images/details.jpg";
import CommentModal from "../../Component/Modal/CommentModal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlog, getParticularBlog } from "../../redux/reducers/BlogSlice";
import moment from "moment";
import HomeService from "../../Service/HomeService";
import { useUserData } from "../Context/UserContext";
import { toast } from "react-hot-toast";
import BreadcrumbsHeader from "../../Component/BreadcrumbsHeader";
import { initializeUseSelector } from "react-redux/es/hooks/useSelector";

export default function Details() {
  const { singleBlogData, singleBlogLoading } = useSelector(
    (state) => state.allBlogReducer
  );
  const { isLogin } = useUserData();

  const { slug, id } = useParams();
  console.log("dajshdfjk", slug, id);

  ///
  // const [blogData, setBlogData] = useState([]);
  // const [currentIndex, setCurrentIndex] = useState(1);
  // const handleNext =()=>{
  //   setCurrentIndex((prevIndex) => (prevIndex + 1) % singleBlogData.length);
  //   singleBlogData && setParticularData(singleBlogData[currentIndex]);
  // }
  ///*/

  console.log("singleBlogData", singleBlogData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [comment, setComment] = useState("");
  const [commentData, setCommentData] = useState([]);

  console.log("commentData", commentData);

  const [modalA, setModalA] = useState(false);

  useEffect(() => {
    dispatch(getParticularBlog(slug));
    // dispatch(getAllBlog())

    getComment();
  }, []);

  const handleClick = () => {
    if (isLogin) {
      setModalA(!modalA);
    } else {
      navigate("/login");
    }
  };

  const handleComment = async () => {
    const sendData = {
      blogId: singleBlogData?._id,
      comments: comment,
    };
    console.log("first", sendData);

    const res = await HomeService.addUserComment(sendData);
    console.log("resComment", res);
    if (res && res?.status) {
      setComment("");
      setModalA(false);
      toast.success("Comment added successfully");
    }
  };

  const getComment = () => {
    console.log("SINGLEDATS", singleBlogData);

    if (singleBlogData) {
      console.log("RESPONSECOMMENTSghfh", id);
      HomeService.viewComments(id)
        .then((res) => {
          console.log("RESPONSECOMMENTS", res);
          if (res && res.status) {
            setCommentData(res?.data);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // else{
    //     toast.error("fsfgdg")
    // }

    // console.log("IIDHDFHF",singleBlogData?._id);
    // const res = await HomeService.viewComments(singleBlogData?._id);
    // console.log("resGetcom", res);
    // if (res && res?.status) {
    //     setCommentData(res?.data);
    // }
  };

  return (
    <>
      <section id="details">
        <div className="nagacontainer">
          <div className="row mt-2">
            <BreadcrumbsHeader
              breadArr={[
                {
                  name: "Blog",
                  path: "/blog",
                },
                {
                  // name: "1Empowering Nagaland's Cultural Heritage: The Importance of a Digital Library",
                  name: singleBlogData?.blogTitle,
                  // path: "",
                },
                // "Thematic Content", singleThematic?.name
              ]}
            />
          </div>
          <div className="details_body">
            <div className="detailsright">
              <div className="details_rightcontent">
                <div className="details_text">
                  <h3 className="details_head">{singleBlogData?.blogTitle}</h3>

                  <div
                    className="d-flex align-items-center"
                    style={{ maxWidth: "100%", marginBottom: "10" }}
                  >
                    <div>
                      <p className="details_date">
                        {/* {moment(singleBlogData?.createdOn).format("LL")} */}
                        {moment(singleBlogData?.createdOn).format("LL")} |{singleBlogData?.category?.name}|{singleBlogData?.readingTime} min read|Author:{singleBlogData?.author}
                      </p>
            
                      {/* <div className="detailsgrey-rule" /> */}
          
                    </div>
                    <div style={{marginLeft:'20px'}}><button className="Comment_btn" onClick={handleClick}>
                  {/* <i className="fa-regular fa-message mr-2"></i>  */}
                  Comment{" "}
                  <span className="ml-1">
                    ({commentData ? commentData?.length : 0})
                  </span>
                </button></div>
                  </div>
             
                </div>
                <div style={{textAlign:'justify'}}>
                  <div className="details_img floatright">
                    <img src={singleBlogData?.blogImage} alt="img" />
                  </div>
                  <div className="details_text">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: singleBlogData?.description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="justify-content-end  d-flex pb-3">
            
                <a
                  // onClick={handleNext}
                  href="#"
                  className="readBtn"
                >
                  Next Read
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {modalA && (
        <CommentModal
          closemodal={setModalA}
          comment={comment}
          setComment={setComment}
          handleComment={handleComment}
          commentData={commentData}
        />
      )}
    </>
  );
}
