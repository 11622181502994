import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import img1 from "../../Images/angami1.jpg";
import img2 from "../../Images/angami2.avif";
import img3 from "../../Images/angami3.avif";
import img4 from "../../Images/angami4.avif";
import img5 from "../../Images/angami5.avif";
import img6 from "../../Images/angami6.jpg";
import HomeService from "../../Service/HomeService";
import toast from "react-hot-toast";

function FolklorePage() {

    // const data = [
    //     { id: 1, linkgo: "/thematic-details/battle-of-kohima", ImgWrap: img1, name: "Angami", descripti: "hi.......", },
    //     { id: 2, linkgo: "/thematic-details/battle-of-kohima", ImgWrap: img2, name: "Ao", descripti: "hi.......", },
    //     { id: 3, linkgo: "/thematic-details/battle-of-kohima", ImgWrap: img3, name: "Chakhesang", descripti: "hi.......", },
    //     { id: 4, linkgo: "/thematic-details/battle-of-kohima", ImgWrap: img4, name: "Sumi", descripti: "hi.......", },
    //     { id: 5, linkgo: "/thematic-details/battle-of-kohima", ImgWrap: img5, name: "Lotha", descripti: "hi.......", },
    //     { id: 6, linkgo: "/thematic-details/battle-of-kohima", ImgWrap: img6, name: "Konyak", descripti: "hi.......", },
    // ];

    const [folkLore, setFolkLore] = useState([{}]);
    console.log("folkLore",folkLore);
    const [folkloreDesc , setFolkloreDesc] = useState("")

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        fetchFolkloreDes()
        HomeService.ViewFolklore()
            .then((res) => {
                if (res && res?.status) {
                    setFolkLore(res?.data);
                } else {
                    toast.error(res?.message);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])


    const fetchFolkloreDes = () => {
        HomeService?.ViewFlokloreDesc()
        .then((res) => {
            if (res && res?.status) {
                setFolkloreDesc(res?.data?.description);
            } else {
                toast.error(res?.message);
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    return (
        <main className="folklore_Page">
            <section className="bradecum_head">
                <div className="nagacontainer">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 m-auto">
                            <div
                                className="head"
                                data-aos="fade-up"
                                data-aos-duration="3000"
                            >
                                <h4>Folklore Collections</h4>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-12 m-auto">
                            <div
                                className="text"
                                data-aos="fade-up"
                                data-aos-duration="3000"
                            >
                                <p style={{ maxWidth: "100%" }}>
                                    {folkloreDesc}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="battle_Kohima">
                <div className="nagacontainer">
                    <div
                        className="box_wraper"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                    >

                        {
                            folkLore.map((item, key) => (

                                <div className="box_book" key={key}>
                                    <Link to={`/folklore-details/${item?.slug}`}>
                                        <div className="img_area">
                                            <img
                                                src={item.url}
                                                className="img-fluid"
                                                alt="..."
                                            />
                                            <div class="overlay"></div>
                                        </div>
                                        <div className="name">
                                            <h4>{item.name}</h4>
                                        </div>
                                        {/* <div className="descript">
                                        <p>{item.descripti}</p>
                                    </div> */}
                                    </Link>
                                </div>

                            ))}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default FolklorePage