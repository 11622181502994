import React from 'react'

export default function Index() {
    return (
        <>

            <section className="cookie-area section-tb-padding my-5">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="terms-title">
                                <h1>Cookie policy</h1>
                            </div>
                            <div className="cookie-content">
                                <div className='wrap'>
                                    <h3>
                                        What are cookies?
                                    </h3>
                                    <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
                                    <p className='mt-2'>Cookies set by the website owner (in this case, Termly ) are called "first-party cookies." Cookies set by parties other than the website owner are called "third-party cookies." Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
                                </div>
                                <div className='wrap'>
                                    <h3>
                                        Why do we use cookies?
                                    </h3>

                                    <p> We use first-and third-party cookies for several reasons. Some cookies are required for technical
                                        reasons in order for our Website to operate, and we refer to these as
                                        "essential" or "strictly necessary" cookies. Other cookies also enable us to
                                        track and target the interests of our users to enhance the experience on our
                                        Online Properties.
                                        Third parties serve cookies through our Website for advertising, analytics,
                                        and other purposes.
                                        This is described in more detail below.</p>

                                </div>
                                <div className='wrap'>
                                    <h3>
                                    How can I control cookies?
                                    </h3>
                                    <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</p>
                                    <p className='mt-2'>The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.</p>
                                    <p className='mt-2'>The specific types of first- and third-party cookies served through our Website and the purposes they perform are described in the table below (please note that the specific </p>
                                </div>
                      
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
