import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const GoToTop = () => {
    const location = useLocation();
    console.log("locationGotop", location);

    const onTop = () => {
        // window.scrollTo({
        //     top: 0,
        //     left: 0,
        //     behavior: "auto",
        // });

        const element = document.getElementById("root");
        element.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        onTop();
    }, [location])

    return null;
}

export default GoToTop
