import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BreadcrumbsHeader from "../../Component/BreadcrumbsHeader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import h from "../../Images/history.jpg";
import c from "../../Images/list1.jfif";
import audio from "../../Images/record.png";
import video from "../../Images/videos/Intro-Short-Video-Edinboro-University.mp4";
import Pagination from "../../Component/Pagination";
import demoImg from "../../Images/demoImage.jpg";
import sandDemo from "../../Images/sandDemoPic.jpg";
import HistoryLeftCard from "../../Component/HistoryLeftCard";
import HttpClient from "../../utils/HttpClient";
import { RotatingLines } from "react-loader-spinner";
import HomeService from "../../Service/HomeService";
import { async } from "q";
import NoDataFound from "../../Helper/NoDataFound";
import { useDispatch, useSelector } from "react-redux";
import { getColCat } from "../../redux/reducers/CollectionCategorySlice";
import ReactPlayer from "react-player";
import toast from "react-hot-toast";
import DOMPurify from "dompurify";
import PhotoGalleryModal from "../../Component/Modal/PhotoGalleryModal";
import PaginationControls from "../../Component/Paginations";

export default function CategoryDetails() {
  const { colCatData } = useSelector((state) => state.colCatReducer);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [image, setImage] = useState([]);

  console.log("IMAGEDATA", image);

  const [page, setPage] = useState(1);
  const numberOfDataInEachPage = 15;

  console.log("Image", image);
  const [select, setSelect] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  const [photoCategory, setPhotoCategory] = useState([]);
  const [currType, setCurrType] = useState("Books");
  const [singleColCat, setSingleColCat] = useState({});

  const [videoPlay, setVideoPlay] = useState(false);
  const [videoLinkPlay, setVideoLinkPlay] = useState(false);
  const [videosData, setVideosData] = useState({});
  const [allPhoto, setSpecificPhoto] = useState([]);
  const [mergeImage, setMergeImage] = useState([]);

  console.log("MergeImageds", mergeImage);

  // const [categoryData]

  console.log("ALLGALERY fdsfsd fsd", allPhoto);

  console.log("VIDEOSDATA", videosData);

  const [playAudio, setPlayAudio] = useState(false);
  const [audioData, setAudioData] = useState({});

  console.log("VideoPlay", videoPlay);
  console.log("videoLinkPlay", videoLinkPlay);
  const [model, setModel] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  console.log("feature item data", location.state);

  // console.log("singleColCat", singleColCat);

  const Message = "Stay tuned. Coming soon!!";
  const defaultImage =
    "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg";
  let typeName = [
    "Books",
    "Documents",
    "Audios",
    "Videos",
    "Photographs",
    "Papers and Journals",
    "Reports",
  ];

  const handleType = async (name) => {
    console.log("type", name);
    setCurrType(name);
    const data = {
      type: name,
    };
    // console.log("HandleRes", data)
    let result = await HomeService.viewcollectioncat(id, data);
    console.log("collectionRes", result);
    if (result && result.status) {
      setCategoryData(result.data);
    }
  };

  const allPhotos = async () => {
    HomeService.allPhotoCategory(id)
      .then((res) => {
        console.log("Response Add", res);
        if (res && res.status) {
          setImage(res?.data);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ParticularGallery = (item) => {
    let arr1 = [];
    let arr2 = [];
    image.forEach((ele) => {
      if (item?._id === ele?._id) {
        arr1.push(item);
      } else {
        arr2.push(ele);
      }
    });

    setMergeImage([...arr1, ...arr2]);
    console.log("IMAGEDATA", image, item);
    console.log("MergeData", [...arr1, ...arr2]);

    let data = [
      {
        name: item?.title,
        image: item?.image,
        desc: item?.desc,
      },
    ];
    // setPhotoCategory(item);
    // console.log("ITEM", item);
    setModel(true);

    // HomeService.viewPartcularGallery(item?.slug)
    //   .then((res) => {
    //     console.log("Response Add", res.data.length);
    //     if (res && res.status) {
    //       if (res.data.length === 0) {
    //         setSpecificPhoto([...data]);
    //       } else {
    //         setSpecificPhoto([...data, ...res?.data[0]?.photos]);
    //       }
    //     } else {
    //       toast.error(res?.message);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    const data = colCatData.find((item) => item?.slug === id);
    setSingleColCat(data);
  }, [colCatData]);

  useEffect(() => {
    // if (location.state == "Photographs") {
    //   allPhotos();
    //   handleType("Photographs");
    //   dispatch(getColCat());
    // }
    // else {
    console.log("here");
    allPhotos();
    handleType("Books");
    // handleType("Photographs");
    dispatch(getColCat());
    // }
  }, []);

  // useEffect(() => {
  //   handleType("Photographs");
  // });

  return (
    <>
      <div className="tabsPart mt-2 mb-5">
        <div className="nagacontainer">
          <section id="history">
            <div className="history_body">
              <div className="row">
                <BreadcrumbsHeader
                  // breadArr={["General Collection", location?.state]}
                  breadArr={[
                    {
                      name: "General Collection",
                      path: "/collection",
                    },
                    {
                      name: singleColCat?.name,
                      path: "",
                    },
                  ]}
                />
              </div>

              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <Tabs>
                    <TabList className="tabListTag">
                      {typeName.map((ele) => (
                        <Tab onClick={() => {handleType(ele) ; setPage(1)}}>
                          <a className="">
                            <span>{ele}</span>
                          </a>
                        </Tab>
                      ))}
                    </TabList>

                    {/* books */}
                    <TabPanel>
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                          <HistoryLeftCard singleColCat={singleColCat} />
                        </div>
                        {/* {loading && <div style={{ margin: "auto" }}>
                                                    <RotatingLines
                                                        strokeColor="grey"
                                                        strokeWidth="5"
                                                        animationDuration="0.75"
                                                        width="96"
                                                        visible={true}
                                                    />
                                                </div>} */}
                        <div style={{ margin: "auto" }}></div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                          {categoryData &&
                            categoryData
                              .slice(
                                page * numberOfDataInEachPage -
                                  numberOfDataInEachPage,
                                page * numberOfDataInEachPage
                              )
                              ?.map((ele) => (
                                <div className="history_right">
                                  <div className="right_list">
                                    <Link to={`/types-details/${ele?.slug}`}>
                                      <div className="list-item">
                                        <div className="listimg_wrapper">
                                          {/* <img src='https://cdn.pixabay.com/photo/2018/08/26/23/55/woman-3633737_1280.jpg' /> */}
                                          <img src={ele?.collectionImage} />
                                        </div>
                                        <div className="list_text">
                                          <a href="#">
                                            <h6>{ele.title}</h6>
                                          </a>
                                          {/* <p>14/06/2023</p> */}
                                          <p>{ele.desc}</p>

                                          <div
                                            style={{
                                              maxWidth: "300px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <p>
                                              <a href="#">View Summary</a>
                                            </p>
                                            <p className="italic">
                                              Type: Books
                                            </p>
                                          </div>
                                          <p>
                                            <span className="citation">
                                              Source/Author: {ele.author}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              ))}

                          {!categoryData?.length && <NoDataFound />}
                        </div>
                      </div>

                      {categoryData &&
                        categoryData.length > numberOfDataInEachPage && (
                          <Pagination
                            page={page}
                            setPage={setPage}
                            numberOfPages={Math.ceil(
                              categoryData.length / numberOfDataInEachPage
                            )}
                          />
                        )}
                    </TabPanel>

                    {/* document */}
                    <TabPanel>
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                          <HistoryLeftCard singleColCat={singleColCat} />
                        </div>
                        <div style={{ margin: "auto" }}>
                          {/* {particularCollections.length === 0 ? Message : ""} */}
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                          {categoryData &&
                            categoryData
                              ?.slice(
                                page * numberOfDataInEachPage -
                                  numberOfDataInEachPage,
                                page * numberOfDataInEachPage
                              )
                              .map((ele) => (
                                <div className="history_right">
                                  <div className="right_list">
                                    <Link to={`/types-details/${ele?.slug}`}>
                                      <div className="list-item">
                                        <div className="listimg_wrapper Documents">
                                          {/* <img src="https://cdn.pixabay.com/photo/2018/08/26/23/55/woman-3633737_1280.jpg" /> */}
                                          <img src={ele.collectionImage} />
                                        </div>
                                        <div className="list_text">
                                          <a href="#">
                                            <h6>{ele.title}</h6>
                                          </a>
                                          {/* <p>14/06/2023</p> */}
                                          <p>{ele.desc}</p>

                                          <div
                                            style={{
                                              maxWidth: "300px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <p>
                                              <a href="#">View Summary</a>
                                            </p>
                                            <p className="italic">
                                              Type: Document
                                            </p>
                                          </div>
                                          <p>
                                            <span className="citation">
                                              Source/Author: {ele.author}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              ))}

                          {!categoryData?.length && <NoDataFound />}
                        </div>
                      </div>
                      {categoryData &&
                        categoryData.length > numberOfDataInEachPage && (
                          <Pagination
                            page={page}
                            setPage={setPage}
                            numberOfPages={Math.ceil(
                              categoryData.length / numberOfDataInEachPage
                            )}
                          />
                        )}
                    </TabPanel>

                    {/* Audio */}
                    <TabPanel>
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                          <HistoryLeftCard singleColCat={singleColCat} />
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                          <div
                            className="history_right"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="right_list">
                              {categoryData &&
                                categoryData
                                  ?.slice(
                                    page * numberOfDataInEachPage -
                                      numberOfDataInEachPage,
                                    page * numberOfDataInEachPage
                                  )
                                  .map((ele) => (
                                    //   <Link to={`/types-details/${ele?.slug}`}>
                                    <div
                                      className="list-item"
                                      onClick={() => {
                                        if (
                                          ele?.uploadCollection == "" &&
                                          ele?.link == ""
                                        ) {
                                          toast.error(
                                            "There is no uploadCollection and link is available"
                                          );
                                        } else {
                                          setAudioData(ele);
                                          setPlayAudio(true);
                                        }
                                      }}
                                    >
                                      <div className="listimg_wrapper">
                                        {ele?.collectionImage !== "" ? (
                                          <img src={ele.collectionImage}></img>
                                        ) : (
                                          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"></img>
                                        )}
                                      </div>
                                      <div className="list_text">
                                        <a>
                                          <h6>{ele.title}</h6>
                                        </a>

                                        <p>{ele.desc}</p>
                                        <div
                                          style={{
                                            maxWidth: "300px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <p>
                                            <a>View Summary</a>
                                          </p>
                                          <p className="italic">Type: Audios</p>
                                        </div>
                                        <p>
                                          <span className="citation">
                                            Source/Author: {ele.author}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    //   </Link>
                                  ))}

                              {playAudio && (
                                <section className="audiopopup_area">
                                  <div
                                    className="wrapbox"
                                    style={{
                                      height: "20%",
                                      width: "60%",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <audio controls autoPlay loop>
                                      <source
                                        src={
                                          audioData?.uploadCollection
                                            ? audioData?.uploadCollection
                                            : audioData?.link
                                        }
                                        type="audio/mpeg"
                                      />
                                    </audio>

                                    <button
                                      className="close_btn"
                                      onClick={() => setPlayAudio(false)}
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </button>
                                  </div>
                                </section>
                              )}

                              {!categoryData?.length && <NoDataFound />}
                            </div>
                          </div>
                        </div>
                      </div>

                      {categoryData &&
                        categoryData.length > numberOfDataInEachPage && (
                          <Pagination
                            page={page}
                            setPage={setPage}
                            numberOfPages={Math.ceil(
                              categoryData.length / numberOfDataInEachPage
                            )}
                          />
                        )}
                    </TabPanel>

                    {/* videos */}
                    <TabPanel>
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                          <HistoryLeftCard singleColCat={singleColCat} />
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                          <div
                            className="history_right"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="right_list">
                              {categoryData &&
                                categoryData
                                  ?.slice(
                                    page * numberOfDataInEachPage -
                                      numberOfDataInEachPage,
                                    page * numberOfDataInEachPage
                                  )
                                  .map((ele) => (
                                    //   <Link to={`/types-details/${ele?.slug}`}>
                                    <div
                                      className="list-item"
                                      onClick={() => {
                                        setVideosData(ele);
                                        if (ele?.uploadCollection)
                                          setVideoPlay(true);
                                        else if (ele?.link)
                                          setVideoLinkPlay(true);
                                        else
                                          toast.error(
                                            "There is no uploadCollection & link is Available"
                                          );
                                      }}
                                    >
                                      <div className="listimg_wrapper video_list_wrapper">
                                        {/* <video autoPlay="true" poster muted>
                                                                                <source src={video} type="video/mp4" />
                                                                            </video> */}
                                        {/* <div className="listimg_wrapper">
                                                                                <img src={ele.collectionImage}></img>
                                                                            </div> */}

                                        {ele?.uploadCollection ? (
                                          <div
                                            className="video_"
                                            style={{
                                              height: "100%",
                                              width: "100%",
                                            }}
                                          >
                                            <video poster controls>
                                              <source
                                                src={ele?.uploadCollection}
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                        ) : (
                                          <div>
                                            <ReactPlayer
                                              className="react-player"
                                              controls
                                              width="14rem"
                                              height="9rem"
                                              // playing={true}
                                              url={ele.link}
                                              config={{
                                                youtube: {
                                                  playerVars: { showinfo: 1 },
                                                },
                                                facebook: {
                                                  appId: "12345",
                                                },
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>

                                      <div className="list_text">
                                        <h6>{ele.title}</h6>

                                        {/* <p>14/06/2023</p> */}
                                        {/* <p>{ele.desc}</p> */}
                                        <div
                                          style={{
                                            maxWidth: "300px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <p>
                                            <>View Summary</>
                                          </p>
                                          <p className="italic">Type: Videos</p>
                                        </div>
                                        <p>
                                          <span className="citation">
                                            Source/Author: {ele.author}
                                          </span>
                                        </p>
                                      </div>
                                    </div>

                                    //   </Link>
                                  ))}

                              {videoPlay && (
                                <section
                                  className="videopopup_area"
                                  onClick={() => {
                                    setVideoPlay(false);
                                  }}
                                >
                                  <div
                                    className="wrapbox"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "90%",
                                      width: "70%",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <video
                                      controls
                                      autoPlay
                                      loop
                                      width="320"
                                      height="240"
                                    >
                                      <source
                                        src={videosData?.uploadCollection}
                                        type="video/mp4"
                                      />
                                    </video>
                                    <button
                                      className="close_btn"
                                      onClick={() => {
                                        setVideoPlay(false);
                                      }}
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </button>
      
                                    <div
                                      style={{
                                        padding: "1rem",
                                        overflow: "auto",
                                        wordWrap: "break-word",
                                        width: "100%",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: videosData?.summery,
                                      }}
                                    >
                                   
                                    </div>
                                
                                  </div>
                                </section>

                              )}

                              {videoLinkPlay && (
                                <section
                                  className="videopopup_area"
                                  onClick={() => {
                                    setVideoLinkPlay(false);
                                  }}
                                >
                                  <div
                                    className="wrapbox"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "90%",
                                      width: "70%",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div
                                      style={{ height: "100%", width: "100%" }}
                                    >
                                      <ReactPlayer
                                        className="react-player"
                                        controls
                                        width="100%"
                                        height="100%"
                                        playing={true}
                                        url={videosData?.link}
                                        config={{
                                          youtube: {
                                            playerVars: {
                                              showinfo: 1,
                                            },
                                          },
                                          facebook: {
                                            appId: "12345",
                                          },
                                        }}
                                      />
                                    </div>

                                    <button
                                      className="close_btn"
                                      onClick={() => setVideoLinkPlay(false)}
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </button>

                                    <div
                                      style={{
                                        padding: "1rem",
                                        overflow: "auto",
                                        wordWrap: "break-word",
                                        width: "100%",
                                      }}
                                    >
                                      <h7
                                        dangerouslySetInnerHTML={{
                                          __html: videosData?.summery,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </section>
                              )}

                              {!categoryData?.length && <NoDataFound />}
                            </div>
                          </div>
                        </div>
                      </div>

                      {categoryData &&
                        categoryData.length > numberOfDataInEachPage && (
                          <Pagination
                            page={page}
                            setPage={setPage}
                            numberOfPages={Math.ceil(
                              categoryData.length / numberOfDataInEachPage
                            )}
                          />
                        )}
                    </TabPanel>

                    {/* PHOTOGRAPHY */}
                    <TabPanel>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                          <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 figAllDiv">
                            {image &&
                              image
                                ?.slice(
                                  page * numberOfDataInEachPage -
                                    numberOfDataInEachPage,
                                  page * numberOfDataInEachPage
                                )
                                .map((ele) => (
                                  <div className="photoFigDiv">
                                    <div
                                      onClick={() => {
                                        ParticularGallery(ele);
                                      }}
                                    >
                                      <figure className="photoFig">
                                        <img src={ele.image} />
                                        {/* <div className="overlay"></div> */}
                                      </figure>
                                      <p className="photoFigAfter">
                                        {ele.title}
                                      </p>
                                    </div>
                                  </div>
                                ))}

                            {!image?.length && <NoDataFound />}
                          </div>
                        </div>
                      </div>
                      {
                        image && image.length > numberOfDataInEachPage && <Pagination
                        page={page}
                        setPage={setPage}
                        numberOfPages={Math.ceil(
                          image.length / numberOfDataInEachPage
                        )}
                      />
                      }
                    </TabPanel>

                    {model && (
                      <PhotoGalleryModal
                        closemodal={setModel}
                        // galleryData={allPhoto}
                        galleryData={mergeImage}
                      />
                    )}

                    {/* papers and journals */}
                    <TabPanel>
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                          <HistoryLeftCard singleColCat={singleColCat} />
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                          {categoryData &&
                            categoryData
                              ?.slice(
                                page * numberOfDataInEachPage -
                                  numberOfDataInEachPage,
                                page * numberOfDataInEachPage
                              )
                              .map((item, i) => (
                                <div className="history_right">
                                  <div className="right_list">
                                    <Link to={`/types-details/${item?.slug}`}>
                                      <div className="list-item">
                                        <div className="listimg_wrapper">
                                          <img
                                            src={item?.collectionImage}
                                            alt="img"
                                          />
                                        </div>
                                        <div className="list_text">
                                          <a href="#">
                                            <h6>{item?.title}</h6>
                                          </a>
                                          {/* <p>14/06/2023</p> */}
                                          <p>{item.desc}</p>

                                          <div
                                            style={{
                                              maxWidth: "300px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <p>
                                              <a href="#">View Summary</a>
                                            </p>
                                            <p>Type: Paper & Journal</p>
                                          </div>
                                          <p>
                                            <span className="citation">
                                              Source/Author: {item?.author}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              ))}

                          {!categoryData?.length && <NoDataFound />}
                        </div>
                      </div>
                      {categoryData &&
                        categoryData.length > numberOfDataInEachPage && (
                          <Pagination
                            page={page}
                            setPage={setPage}
                            numberOfPages={Math.ceil(
                              categoryData.length / numberOfDataInEachPage
                            )}
                          />
                        )}
                    </TabPanel>

                    {/* reports */}
                    <TabPanel>
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                          <HistoryLeftCard singleColCat={singleColCat} />
                        </div>

                        <div style={{ margin: "auto" }}>
                          {/* {particularCollections.length === 0 ? Message : ""} */}
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                          {categoryData &&
                            categoryData
                              ?.slice(
                                page * numberOfDataInEachPage -
                                  numberOfDataInEachPage,
                                page * numberOfDataInEachPage
                              )
                              .map((ele, i) => (
                                <div className="history_right" key={i}>
                                  <div className="right_list">
                                    <Link to={`/types-details/${ele?.slug}`}>
                                      {" "}
                                      <div className="list-item">
                                        <div className="listimg_wrapper">
                                          {/* <img src='https://escholarship.org/cms-assets/af55356682a32bb6cfd74d4456793f3c533f50b529f9cc8c0249f51c65a368a8' /> */}
                                          <img src={ele?.collectionImage} />
                                        </div>
                                        <div className="list_text">
                                          <a href="#">
                                            <h6>{ele?.title}</h6>
                                          </a>
                                          {/* <p>14/06/2023</p> */}
                                          <p>{ele?.desc}</p>
                                          <div
                                            style={{
                                              maxWidth: "300px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <p>
                                              <a href="#">View Summary</a>
                                            </p>
                                            <p className="italic">
                                              Type: Reports
                                            </p>
                                          </div>
                                          <p>
                                            <span className="citation">
                                              Source/Author: {ele?.author}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              ))}

                          {!categoryData?.length && <NoDataFound />}
                        </div>
                      </div>
                      {categoryData &&
                        categoryData.length > numberOfDataInEachPage && (
                          <Pagination
                            page={page}
                            setPage={setPage}
                            numberOfPages={Math.ceil(
                              categoryData.length / numberOfDataInEachPage
                            )}
                          />
                        )}
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* {categoryData?.length > 4 ? <Pagination /> : ""} */}
    </>
  );
}
