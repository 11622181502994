import React, { useEffect, useState } from 'react'
import sandDemo from '../Images/sandDemoPic.jpg';
import demoImg from '../Images/demoImage.jpg'
import { Link, useLocation, useParams } from 'react-router-dom'
import HttpClient from '../utils/HttpClient';
import HomeService from '../Service/HomeService';
import moment from 'moment';
import NoDataFound from '../Helper/NoDataFound';

function DetailsPhoto() {
    const { id } = useParams()
    const location = useLocation()

    const [photoData, setphotoData] = useState({});
    const [specificPhoto, setSpecificPhoto] = useState([])
    const [insidePhots, setInsidePhotos] = useState([])
    const [allPhotos, setAllPhotos] = useState({});

    console.log("specificPhoto", specificPhoto)

    const partGallery = async () => {
        const res = await HomeService.viewPartcularGallery(id)
        console.log("partGallery", res)
        if (res && res?.data) {
            if (res?.data?.length) {
                setphotoData(res?.data[0]?.category);
                setSpecificPhoto(res?.data[0]?.photos);
            }
        }
    }
    const allPhotosInParticularSection = () => {
        setAllPhotos(location?.state)
    }
    useEffect(() => {
        partGallery()
        allPhotosInParticularSection()
    }, [])
    return (
        <>
            <section className='cloudsRowSec'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-sm-3 mb-sm-0 mb-2'>
                            <h6 className='clouSmallHead'>{photoData?.title}</h6>
                            <p className='clouSmallDate'>{moment(photoData?.createdOn).format("LL")}</p>
                            <p className='clouSmallText'>{photoData?.desc}</p>
                        </div>


                        <div className='col-sm-9'>
                            <div className='row row-cols-2 photoSubDivsRow'>
                                {specificPhoto && specificPhoto?.map((item, i) => (
                                    <div className='photoSubFigDiv' key={i}>
                                        <figure className='photoSubFig'>
                                            <img src={item?.image} />
                                        </figure>
                                        <p className='photoSubAfterText'>{item?.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {
                        !specificPhoto?.length && <NoDataFound />
                    }

                    {/* <div className='row allPhotoRow'>
                        <h3 className='allPHead col-12'>ALL PHOTOS</h3>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='row row-cols-3 figAllDiv'>

                                <Link to='/more-details'>
                                    <div className="photoFigDiv">
                                        <figure className='photoFig'>
                                            <img src={allPhotos?.image} />
                                        </figure>
                                        <p className='photoFigAfter'>{allPhotos?.title}</p>
                                    </div>
                                </Link>

                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default DetailsPhoto