import React, { useEffect, useState } from "react";
import ab from "../../Images/p1.png";
import ac from "../../Images/p2.png";
import cb from "../../Images/p3.png";
import db from "../../Images/p4.png";
import acd from "../../Images/takeone.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import Slider from "react-slick";
import pattern from "../../Images/pattern.jpg";
const ContributeHome = () => {
  const [allLogo, setAllLogo] = useState([]);

  console.log("ALLLOGO", allLogo);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 695,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // aos animation
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    ViewAllContributingPartnersLogo();
  }, []);

  const ViewAllContributingPartnersLogo = () => {
    HomeService.ViewOurContributingPartnersLogo()
      .then((res) => {
        if (res && res.status) {
          setAllLogo((res?.data));
          //   setCommentData(res?.data);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="partner">
        <div className="nagacontainer">
          <div className="collectionTop">
            <h3 className="feco">Our Contributing Partners</h3>
            <figure className="ptrnFig">
              <img src={pattern} alt="" />
            </figure>
          </div>
          {/* <h3 className='sia'>CONTRIBUTING PARTNERS</h3> */}
          {/* <div
            className="main-flx pt-5"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            {allLogo && (
              <>
                {allLogo.map((item) => {
                  return (
                    <div className="sub-flex">
                      <figure className="partnerLogoFig">
                        <img src={item?.logo} className="partner-logo" alt="" />
                      </figure>
                    </div>
                  );
                })}
              </>
            )}
          </div> */}

          <div className="collection_list">
            <div
            // className="collection_top"
            // data-aos="fade-up"
            // data-aos-duration="3000"
            >
              <Slider {...settings} className="myOwnSlickSlide">
                {allLogo &&
                  allLogo?.map((item, i) => (
                    <div className="sub-flex">
                      <figure className="partnerLogoFig">
                        <img src={item?.logo} className="partner-logo" alt="" />
                      </figure>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>

          <div className="" style={{ marginTop: "60px" }}>
            <p className="cmeJoinText">
              Join our esteemed partners and contribute your creative work, such
              as blogs, vlogs, books, journals, write-ups, essays, folklore,
              short stories, or any other locally and indigenous-inspired
              content or educational content.{" "}
            </p>

            <p className="cmeJoinText">
              Leave us a message or give us a call, and we will be delighted to
              establish a connection with you and initiate the collaboration
              process. Your valuable contributions will be sincerely appreciated
              and play a significant role in our shared creative journey.{" "}
            </p>
          </div>
          {/* <div className='contriBtnDiv'>
                        <button className='contriBtn'>CONTRIBUTE</button>
                    </div> */}

          <div
            className="seemore_div"
          // data-aos="fade-up"
          // data-aos-duration="3000"
          >
            <Link to="/partners-page">Meet our partners </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributeHome;
