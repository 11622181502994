import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

function Kashopapubanner({ singleFolkColl }) {
  console.log("singleFolkColl22", singleFolkColl)
  return (
    <>
      <div className="kasho_banner_main">

        {/* <div className="kashopapu_title_box">
          <Link to="/folklore-new">
            <span className="kasho_title_tag" >Folklore &gt;</span>
          </Link>
          {
            singleFolkColl?.flokloreVolume?.[0]?.title &&
            <Link to={"/folklore-Volume/" + singleFolkColl?.flokloreVolume?.[0]?._id}>
              <span className="kasho_title_tag">{singleFolkColl?.flokloreVolume?.[0]?.title} &gt;</span>
            </Link>
          }
          <Link to={"/single-tribe/" + singleFolkColl?.flokloreData?._id} state={{ volumeDetail: singleFolkColl?.flokloreVolume?.[0] }}>
            <span className="kasho_title_tag">{singleFolkColl?.flokloreData?.name} &gt;</span>
          </Link>

          <span className="kasho_title_tag active_tag">{singleFolkColl?.name}</span>
        </div> */}


        <div className="breadcrumbs-header">
          <nav>
            <Link to="/folklore-new">
              Folklore
              <i className="fa-solid fa-angle-right" />
            </Link>

            {singleFolkColl?.flokloreVolume?.[0]?.title &&
              <Link to={"/folklore-Volume/" + singleFolkColl?.flokloreVolume?.[0]?._id} style={{ color: "#fff" }}>
                {singleFolkColl?.flokloreVolume?.[0]?.title}
                <i className="fa-solid fa-angle-right" />
              </Link>
            }

            <Link to={"/single-tribe/" + singleFolkColl?.flokloreData?._id} state={{ volumeDetail: singleFolkColl?.flokloreVolume?.[0] }} style={{ color: "#fff" }}>
              {singleFolkColl?.flokloreData?.name}
              <i className="fa-solid fa-angle-right" />
            </Link>

            <Link to="#" style={{ color: "#fff", fontWeight: "600" }}>
              {singleFolkColl?.name}
            </Link>
          </nav>
        </div>



        <div className="kashopapu_desc_box">
          {/* <div className="kasho_desc_tag">
            <li className="kasho_tagline">Folklore</li>
            <li className="kasho_tagline">Angami</li>
          </div> */}

          <p className="kasho_main_title">{singleFolkColl?.name}</p>
          {singleFolkColl?.subtitle && <p className="kasho_short_text">&#40;{singleFolkColl?.subtitle}&#41;</p>}
        </div>
        <p className="card-description">
          <span className="card-address">
            {moment(singleFolkColl?.createdAt).format("LL")}
          </span>

          {
            singleFolkColl?.readTime &&
            <span className="card-time">
              <i className="fa-regular fa-clock"></i>{singleFolkColl?.readTime} minutes Read
            </span>
          }
        </p>
      </div >
    </>
  )
}

export default Kashopapubanner
