import React from 'react'
import { useLocation } from "react-router-dom";
import { Document, Page,pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const ViewDocuments = () => {

  const location = useLocation();
  console.log("location", location?.state);

  return (
    <>
   
   <iframe title="PDF Preview" src={location?.state} width="100%" height="500px"></iframe>

    </>
  )
}

export default ViewDocuments