import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HomeService from "../../Service/HomeService";


const initialState = {
    colCatData: [],
    loading: false,
    error: ""
}

export const getColCat = createAsyncThunk('collectionCat/getColCat', async () => {
    const result = await HomeService.viewColCat();
    if (result) {
        return result;
    }
})

const colCatSlice = createSlice({
    name: "collectionCategory",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [getColCat.pending]: (state) => {
            state.loading = true;
        },
        [getColCat.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.colCatData = payload.data;
                state.loading = false;
            } else {
                state.colCatData = [];
                state.loading = false;
            }
        },
        [getColCat.rejected]: (state) => {
            state.loading = false;
            state.error = "Eror"
        }
    }
})

export const colCatReducer = colCatSlice.reducer;