import React, { useEffect, useState } from "react";
import book1 from "../../Images/bok-photo1.jpg";
import { Link } from "react-router-dom";
import HomeService from "../../Service/HomeService";
import HttpClient from "../../utils/HttpClient";
import nagapic from "../../Images/bok-photo3.jpg";
import nagap from "../../Images/bok-photo4.jpg";
import { useDispatch, useSelector } from "react-redux";
import { thematicReducer, getThematic } from "../../redux/reducers/ThemeSlice";
import AOS from "aos";
import "aos/dist/aos.css";
import Pegination from "../../Component/Pagination";
import { toast } from "react-hot-toast";
function Thematic() {
  const { themeticData, loading } = useSelector(
    (state) => state.thematicReducer
  );
  const dispatch = useDispatch();
  const [thematicDesc, setThematicDesc] = useState("");

  console.log("themeticData", themeticData);

  const numbers = [1, 2, 3, 4, 5];
  const defauultImage =
    "https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg";

  const listItems = themeticData.map((ele) => (
    <div key={ele?.slug} className="box_book">
      <Link to={`/thematic-details/${ele?.slug}`}>
        <div className="img_area imgONEffect">
          <img
            src={ele?.img ? ele?.img : defauultImage}
            className="img-fluid"
            alt={ele?.name}
          />
          <div className="imgOnHover">
            <p>{ele.content}</p>
          </div>
        </div>
        <div className="name">
          <h4>{ele?.name}</h4>
        </div>
        {/* <div className='text'>
                    <p>Lorem ipsum dolor sit amet</p>
                </div> */}
        {/* {ele?.themeType == "startup" || ele.themeType == "education" ? (
          <div className="descript">
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: ele?.summary,
                }}
              />
            </p>
          </div>
        ) : (
          <div className="descript">
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: ele?.caption,
                }}
              />
            </p>
          </div>
        )} */}
      </Link>
    </div>
  ));

  useEffect(() => {
    dispatch(getThematic());
  }, []);

  // aos animation
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    fetchThematicDesc();
  }, []);

  const fetchThematicDesc = () => {
    HomeService?.ViewThematicDesc()
      .then((res) => {
        if (res && res?.status) {
          setThematicDesc(res?.data?.description);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <main className="thematic_page">
        <section className="bradecum_head">
          <div className="nagacontainer">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 m-auto">
                <div
                  className="head"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <h4>Thematic Content</h4>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-12 m-auto">
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <p>{thematicDesc}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="battle_Kohima">
          <div className="nagacontainer">
            <div
              className="box_wraper"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              {listItems}

              {/***Education & Career****/}
              {/* <Link to="/education-career" className='box_book'>
                                <div className='img_area'>
                                    <img src={nagap} className='img-fluid' alt="" />
                                </div>
                                <div className='name'>
                                    <h4>Education & Career</h4>
                                </div>
                                <div className='text'>
                                    <p>Lorem ipsum dolor sit amet</p>
                                </div>
                                <div className='descript'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lacus erat, posuere</p>
                                </div>
                            </Link> */}
              {/***Education & Career End****/}

              {/***StarupsNagaland start****/}
              {/* <Link to="/StarupsNagaland" className='box_book'>
                                <div className='img_area'>
                                    <img src={nagapic} className='img-fluid' alt="" />
                                </div>
                                <div className='name'>
                                    <h4>Startups in Nagaland</h4>
                                </div>
                                <div className='text'>
                                    <p>Lorem ipsum dolor sit amet</p>
                                </div>
                                <div className='descript'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lacus erat, posuere</p>
                                </div>
                            </Link> */}
              {/***StarupsNagaland End****/}
            </div>
          </div>
        </section>
      </main>

      {/* <Pegination/> */}
    </>
  );
}

export default Thematic;
