import React from 'react'
import img1 from "../../Images/partner/p2.png"
import img2 from "../../Images/partner/p5.png"
import img3 from "../../Images/partner/p1.png"
import img4 from "../../Images/partner/p7.png"
import img5 from "../../Images/partner/p4.png"
import img6 from "../../Images/partner/p6.png"
import img7 from "../../Images/partner/p3.png"
import { Link } from 'react-router-dom'

function PartnersPage() {
    const Categor = [
        { id: 1,  src: img1, head: "Smart City Kohima Ltd", text: "a Nagaland urban innovation leader, champions the Nagaland Digital Library. Committed to advancing technology and infrastructure, they offer insights, fostering digital inclusion and sustainable urban development.", },
        { id: 2,  src: img2, head: "The Department of Art and Culture,", text: "Nagaland, reserves the state's heritage. Partnering with the Digital Library, they share traditional arts, folklore, and history. Enriching Nagaland's cultural tapestry, they foster appreciation for its artistic legacy.", },
        { id: 3,  src: img3, head: "The Government of Nagaland", text: "shapes the state's socio-economic landscape. Partnering with the Digital Library, they commit to digital empowerment and knowledge dissemination. Their contributions reflect the state's heritage, policies, and developmental initiatives.", },
        { id: 4,  src: img4, head: "Project Nable,", text: "our content curation partner, advances Nagaland's literacy and education. Through innovative projects and community-driven initiatives, they empower with knowledge, aligning with the Digital Library's vision for a digitally inclusive society where all thrive.", },
        { id: 5,  src: img5, head: "Shoutout Podcast", text: "captures Nagaland's vibrant culture and diverse voices through engaging conversations and storytelling, resonating with people locally and beyond. Enriching the Digital Library, they offer authentic perspectives and meaningful dialogues.", },
        { id: 6,  src: img6, head: "TakeOne Production", text: "is a creative powerhouse, specializing in visual storytelling and media production. Their innovative approach adds a dynamic dimension to the Digital Library, showcasing Nagaland's artistic prowess and cultural richness globally.", },
        { id: 7,  src: img7, head: "Nagaland University,", text: "renowned for academic excellence, enriches the Digital Library with scholarly research, publications, and educational resources. Fostering intellectual inquiry and knowledge exchange, it empowers learners and researchers within the library's digital realm.", },
    ];
    return (
        <>
            <div className='Partners_Page'>
                <section className="bradecum_head">
                    <div
                        className="nagacontainer"
                        // data-aos="fade-up"
                        // data-aos-duration="3000"
                    >
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 m-auto">
                                <div className="head">
                                    <h4>Partner <br />Stories</h4>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-12 m-auto">
                                <div className="text mb-4">
                                    <p style={{ maxWidth: "100%", textAlign: "left", lineHeight: "26px" }}>Welcome to our collaborative space, where partnerships form the cornerstone of our mission. At Nagaland Digital Library, we believe in the power of synergy, where shared visions and collective efforts drive meaningful impact. Our partners stand at the forefront of this journey, each contributing their unique expertise, resources, and passion towards a common goal: to enrich and empower through knowledge.</p>
                                </div>
                                <div className="text">
                                    <p style={{ maxWidth: "100%", textAlign: "left", lineHeight: "26px" }}>This page serves as a testament to the strength of our alliances and the transformative potential of collective action. Join us as we celebrate the remarkable individuals and organizations who continue to inspire, collaborate, and build together towards a brighter future.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='Partnersdescription_area'>
                    <div className='nagacontainer'>
                        <div className='row'>
                        {Categor.map((item, index) => (
                            <div className='col-lg-4 col-md-6 col-12 mb-3' key={index}>
                                <div className='card_box'>
                                    <div className='logoimg'>
                                        <img src={item.src} className='img-fluid' alt='...' />
                                    </div>
                                    <p className='description'>
                                        <strong>{item.head}</strong> 
                                        {item.text}
                                    </p>
                                </div>
                            </div>
                            ))}
                        </div>

                        <div className='seemore_div'>
                            <Link to="/">JOIN US</Link>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default PartnersPage