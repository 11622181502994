import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Images/logo.png";
import booksimg from "../../Images/signupImg.jpg";
import HomeService from "../../Service/HomeService";
import HttpClient from "../../utils/HttpClient";
import { toast } from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function SignUp() {
  const initData = {
    name: "",
    email: "",
    password: "",
    conPass: "",
  };
  const [formData, setFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const [showpass, setShowPass] = useState(false);
  const [connpass, setConnPass] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    conPass: "",
  });

  const navigate = useNavigate();
  // console.log("formError", formError)

  const handleChange = (e) => {
    // const { name, value } = e.target;
    // setFormData((prev) => {
    //   return { ...prev, [name]: value };
    // });

    // Clear the error for the current input field
    // setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    // name field validation
    let input;
    if (
      e.target.name === "name" &&
      e.target.value !== "" &&
      !/^[A-Za-z\s]+$/.test(e.target.value)
    ) {
      e.target.value = "";
    }
    console.log(e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // password icon show / hidden
  const handlepassShow = (e) => {
    e.preventDefault();
    setShowPass((prevShow) => !prevShow);
  };

  //confirm pass icon show and hide
  const handleconpassShow = (e) => {
    e.preventDefault();
    setConnPass((prevShow) => !prevShow);
  };

  const validate = (data) => {
    const emaiRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let error = {};
    if (!data.name) {
      error.name = "Name is required";
    }

    if (!data.email) {
      error.email = "Email is required";
    } else if (!emaiRegex.test(data.email)) {
      error.email = "Please enter a valid Email";
    }

    if (!data.password) {
      error.password = "Password is required";
    } else if (data.password.length < 6) {
      error.password = "Password should be atleast 6 characters";
    }

    if (!data.conPass) {
      error.conPass = "Confirm password is required";
    } else if (data.password != data.conPass) {
      error.conPass = "Confirm password should be equal to password";
    }
    setFormError(error);
    return error;
  };

  const handleRegister = async () => {
    // console.log("errorMsg", Object.keys(validate(formData)).length);
    if (Object.keys(validate(formData)).length) {
      return;
    }

    const sendData = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
    };
    // return
    // const res = await HttpClient.requestData("user-register", sendData, "POST");
    const res = await HomeService.userRegister(sendData);
    console.log("regisRes", res);
    if (res && res?.status) {
      //   toast.success(res?.message);
      toast.success("User Registered Successfully , Awaiting Admin Approval ");
      setFormData(initData);
      navigate("/login");
    } else {
      // toast.error(res?.message);
      toast.error("Email ID already exists");
    }
  };

  return (
    <>
      <section className="loginpage_main">
        <div className="nagacontainer">
          {/* <div className="top_wrap">
                        <div className='login_logo'>
                            <Link to="/">
                                <img src={logo} className='img-fluid' alt='alt' />
                            </Link>
                        </div>
                    </div> */}
          <div className="main">
            <div className="left">
              <img src={booksimg} alt="girl-reading-a-book" />
            </div>
            <div className="right">
              <div className="con">
                <form className="sign-up">
                  {/* <h4>Find your next favorite book!</h4> */}
                  <h4>Sign Up</h4>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter your Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p style={{ color: "red" }}>{errors.name}</p>
                    )}
                    <span style={{ color: "red", fontSize: "15px" }}>
                      {formError.name ? formError.name : null}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red", fontSize: "15px" }}>
                      {formError.email ? formError.email : null}
                    </span>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <div className="d-flex">
                      <input
                        // type="password"
                        type={showpass ? "text" : "password"}
                        className="form-control"
                        id="password"
                        placeholder="Create a password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <button className="eyecon" onClick={handlepassShow}>
                        {showpass ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    <span style={{ color: "red", fontSize: "15px" }}>
                      {formError.password ? formError.password : null}
                    </span>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Confirm Password
                    </label>
                    <div className="d-flex">
                      <input
                        // type="password"
                        type={connpass ? "text" : "password"}
                        className="form-control"
                        id="password"
                        placeholder="Retype Password"
                        name="conPass"
                        value={formData.conPass}
                        onChange={handleChange}
                      />
                      <button className="eyecon" onClick={handleconpassShow}>
                        {connpass ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    <span style={{ color: "red", fontSize: "15px" }}>
                      {formError.conPass ? formError.conPass : null}
                    </span>
                  </div>
                  <div
                    className="btn submit-btn"
                    onClick={() => handleRegister()}
                  >
                    Create account
                  </div>
                </form>
                <p className="hr-lines"> OR </p>
                {/* <div className="social-sign-up">
                                    <button className="btn social">
                                        <i className="fa-brands fa-google" /> Sign up with Google
                                    </button>
                                </div> */}
                <div className="sign-in">
                  <p>
                    Already have an account? <Link to="/login">Log in</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignUp;
