import React from 'react'

export default function Index() {
    return (
      <>
        <section className="faqs-area section-tb-padding my-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="terms-title faq-title">
                  <h1>FAQ</h1>
                  <p>Below are frequently asked questions, you may find the answer for yourself</p>
                </div>
                <div className="faq-box">
                  <ul className="faq-ul">
                    <li className="faq-li">
                      <h3>
                        <span>1.</span> What types of resources are available in the Digital Library of Nagaland?
                      </h3>
                      <span className="faq-desc">
                        The Digital Library of Nagaland aims to offers a wide range of resources including books,
                        journals, archives, images, and videos. These resources primarily focus on the cultural,
                        historical, and linguistic aspects of Nagaland, featuring works from local authors and
                        researchers. We are in a mission to collect, curate and digitize varied collections in various
                        formats like audio recordings, photographs, papers, presentations, reports, and news articles,
                        encompassing indigenous literature and art.
                      </span>
                    </li>
                    <li className="faq-li">
                      <h3>
                        <span>2.</span> How can I access the resources in the Digital Library of Nagaland?
                      </h3>
                      <span className="faq-desc">
                        Access to our digital library is provided free of charge. Users can browse and access our
                        growing collection online.
                      </span>
                    </li>
                    <li className="faq-li">
                      <h3>
                        <span>3.</span> Can I contribute to the Digital Library of Nagaland?
                      </h3>
                      <span className="faq-desc">
                        Yes, contributions from local authors, researchers, and cultural enthusiasts are welcomed. If
                        you have content that you believe fits our collection, please contact us through our submission
                        form on the website or you can also reach out directly via email id
                        digitallibrarynagaland@gmail.com We accept various formats of content that align with our
                        mission of preserving and promoting Nagaland's cultural heritage.
                      </span>
                    </li>
                  </ul>
                  <ul className="faq-ul">
                    <li className="faq-li">
                      <h3>
                        <span>4.</span> Are there any educational or research programs associated with the library?
                      </h3>
                      <span className="faq-desc">
                        The Digital Library of Nagaland vison is to actively collaborates with educational and research
                        institutions to facilitate learning and scholarship. We are working towards to periodically host
                        webinars, workshops, and collaborative projects. Our 'Events' section will be releasing soon,
                        keep an eye for upcoming programs and opportunities to participate.
                      </span>
                    </li>
                    <li className="faq-li">
                      <h3>
                        <span>5.</span> How does the Digital Library of Nagaland ensure the preservation of cultural
                        heritage?
                      </h3>
                      <span className="faq-desc">
                        Our library is committed to the preservation of Nagaland's rich cultural heritage. We employ
                        advanced digital preservation techniques to safeguard our collections and make them accessible
                        for future generations. Additionally, by digitizing and showcasing locally produced content, we
                        contribute to the ongoing efforts of cultural preservation.
                      </span>
                    </li>
                    <li className="faq-li">
                      <h3>
                        <span>6.</span> How to get in touch with the library staff for further assistance.
                      </h3>
                      <span className="faq-desc">
                        Please contact us through our submission form on the website or you can also reach out directly
                        via email id digitallibrarynagaland@gmail.com.
                      </span>
                    </li>
                  </ul>
                  <a href="#" className="btn-style1">
                    Still have a question?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="faq-collapse section-b-padding my-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="faq-title terms-title">
                  <h1>General questions</h1>
                </div>
                <div className="faq-start">
                  <span>Q.1</span>
                  <a
                    href="#collapse-7"
                    data-bs-toggle="collapse"
                    className="collapse-title collapsed"
                    aria-expanded="false"
                  >
                    How are items packaged?
                  </a>
                  <div className="collapse-content collapse" id="collapse-7" style={{}}>
                    <p>All items are carefully packaged as to avoid any form of damage.</p>
                  </div>
                </div>
                <div className="faq-start">
                  <span>Q.2</span>
                  <a
                    href="#collapse-8"
                    data-bs-toggle="collapse"
                    className="collapse-title collapsed"
                    aria-expanded="false"
                  >
                    Who would be responsible for paying local taxes in countries outside of India?
                  </a>
                  <div className="collapse-content collapse" id="collapse-8" style={{}}>
                    <p>
                      Customs duty &amp; other international taxes etc. if applicable will have to be borne by the
                      customer according to the laws of the land.
                    </p>
                  </div>
                </div>
                <div className="faq-start">
                  <span>Q.3</span>
                  <a href="#collapse-9" data-bs-toggle="collapse" className="collapse-title">
                    {' '}
                    How do i pay for a Mikshaa’s purchase?
                  </a>
                  <div className="collapse collapse-content" id="collapse-9">
                    <p>
                      Mikshaa offers you multiple payment methods. Whatever your online mode of payment, you can trust
                      assured that Mikshaa's trusted payment gateway partners use secure encryption technology to keep
                      your transaction details confidential at all times.
                    </p>
                    <p>
                      You may use Internet Banking, Debit Card, Credit Card and Cash on Delivery to make your purchase.
                      We also accept payments made using Visa, MasterCard, American Express and Any Club credit/debit
                      cards.
                    </p>
                  </div>
                </div>
                <div className="faq-start">
                  <span>Q.4</span>
                  <a
                    href="#collapse-10"
                    data-bs-toggle="collapse"
                    className="collapse-title collapsed"
                    aria-expanded="false"
                  >
                    Can i make a credit/debit card or Internet Banking payment through my mobile?
                  </a>
                  <div className="collapse-content collapse" id="collapse-10" style={{}}>
                    <p>
                      Yes, you can make credit card payments through the Mikshaa mobile site. Mikshaa uses 256-bit
                      encryption technology to protect your card information while securely transmitting it to the
                      secure and trusted payment gateways managed by leading banks.
                    </p>
                  </div>
                </div>
                <div className="faq-start">
                  <span>Q.5</span>
                  <a
                    href="#collapse-11"
                    data-bs-toggle="collapse"
                    className="collapse-title collapsed"
                    aria-expanded="false"
                  >
                    Is it safe to use my credit/debit card on Mikshaa?
                  </a>
                  <div className="collapse-content collapse" id="collapse-11" style={{}}>
                    <p>Is it safe to use my credit/debit card on Mikshaa?</p>
                  </div>
                </div>
                <div className="faq-start">
                  <span>Q.6</span>
                  <a href="#collapse-12" data-bs-toggle="collapse" className="collapse-title">
                    Does Craftsvilla store my credit card information?
                  </a>
                  <div className="collapse collapse-content" id="collapse-12">
                    <p>
                      No, Mikshaa does not collect or store your account information at all. Your transaction is
                      authorized at multiple points, first by EBS/CCavenue and subsequently by Visa/MasterCard/Amex
                      secure directly without any information passing through us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
}
