import React from 'react'
import Header2 from './Header2'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'

export default function CollectionLayout() {
  return (
    <>
      <Header2 />
      <div className='mainBody' id="main">
        <Outlet />
      </div>
      <Footer />
    </>
  )
}
