import React from "react";
import { Link } from "react-router-dom";

function TribeCard({ ele }) {
  // let { title, image, desc } = data;
  console.log("elefggg", ele)

  return (
    <>
      <div className="tribe-card">
        <p className="card-title">{ele?.name}</p>
        <div className="card_img">
          <div className="card_tagline">
            <li className="kasho_tagline">Folklore</li>
            <li className="kasho_tagline">Angami</li>
          </div>
          <img src={ele?.url} />
        </div>
        <p className="card-desc">
          {ele?.desc}
        </p>
        <div className="vol-btn">
          <Link className="" to="/single-tribe">
            READ MORE
          </Link>
        </div>
      </div>
    </>
  );
}

export default TribeCard;
