import React from 'react'

function SkeletonLoader() {
  return (
<div className="prod--wrapper">
  <div className="prod--col prod--img">
    <img id="productImage" className="prod--img-graphic skeleton-loader" />
  </div>
  <div className="prod--col prod--details">
    <div className="prod--row prod--name">
      <span id="productName" className="prod--name-text skeleton-loader" />
    </div>
    <div className="prod--row prod--description">
      <span id="productId" className="prod--description-text skeleton-loader" />
    </div>
  </div>
</div>
  )
}

export default SkeletonLoader