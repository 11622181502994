import React from "react";
import "./kashopapu.css";
import { Link } from "react-router-dom";
import moment from "moment";
function KashopapuCard({ ele }) {


  return (
    <div className="kashopapu_card_main">
      <p className="kasho_card_title">{ele?.name}</p>
      <p class="card-description kashopapu_card_description">
        <span class="card-address">{moment(ele?.createdAt).format("LL")}</span>
        {/* <span class="card-time">
          <i class="fa-regular fa-clock"></i>2 minutes Read
        </span> */}
      </p>

      {/* <p className="kasho_desc showThreeLineFolkColl">
        <div dangerouslySetInnerHTML={{ __html: ele?.description }}></div>
      </p> */}


      <div className="kasho_desc">
        <div>{ele?.smallDescription}</div>
      </div>

      <Link to={"/folklore-collection/" + ele?._id} >
        <p className="Kasho_card_link">VIEW POST</p>
      </Link>
    </div>
  );
}

export default KashopapuCard;
