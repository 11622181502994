import React, { useEffect, useMemo, useState } from "react";
import BreadcrumbsHeader from "../../Component/BreadcrumbsHeader";
import a from "../../Images/summaryimg.jfif";
import b from "../../Images/history.jpg";
import c from "../../Images/list4.jfif";
import d from "../../Images/history1.jpg";
import learnM from "../../Images/learnMore.png";
import booksL from "../../Images/booksLogo.png";
import booksG from "../../Images/booksGrp.png";
import docL from "../../Images/docLogo.png";
import videoL from "../../Images/videoLogo.png";
import { Link, useParams } from "react-router-dom";
import HomeService from "../../Service/HomeService";
import TypeModal from "./TypeModal";
import moment from "moment";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";

export default function TypesDetails() {
  const params = useParams();
  console.log("PARAMS", params);
  const [singleColData, setSingleColData] = useState({});
  const [colType, setColType] = useState("");
  const [playAudio, setPlayAudio] = useState(false);
  const [videoPlay, setVideoPlay] = useState();
  const [videoLinkPlay, setVideoLinkPlay] = useState(false);
  const [docsView, setDocsView] = useState();

  const navigate = useNavigate();
  console.log("colType", colType);

  const getSingleCol = async () => {
    const res = await HomeService.viewParticularCollection(params.id);
    console.log("singleReccol", res);
    if (res && res?.status) {
      setSingleColData(res?.data[0]);
      setColType(res?.data[0]?.type);
    }
  };

  const summeryNameMemo = useMemo(() => {
    switch (colType) {
      case "books":
        return "Book";
      case "audios":
        return "Audios";
      case "videos":
        return "Videos";
      case "documents":
        return "Document";
      case "papers and journals":
        return "Papers and Journals";
      case "reports":
        return "Reports";
      default:
        return "";
    }
  }, [colType]);

  const getDocumentType = useMemo(() => {
    switch (colType) {
      case "audios":
        return "audios";
      case "videos":
        return "videos";
      default:
        return "document";
    }
  }, [colType]);
  console.log("getDocumentType", getDocumentType);

  useEffect(() => {
    getSingleCol();
  }, []);

  const openLinkInNewTab = (url) => {
    console.log("URL", url);

    if (url.trim() !== "") {
      // Check if the URL already starts with "http://" or "https://"
      if (!/^https?:\/\//i.test(url)) {
        window.open(`https://${url}`, "_blank");
      } else {
        // setResultUrl(url);
        window.open(url, "_blank");
      }
    }
  };

  const checkUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    } else {
      return url;
    }
  };

  const HamdleClick = (e) => {
    console.log("AAAA", singleColData);

    e.preventDefault();

    if (singleColData?.uploadCollection === "" && singleColData?.url === "") {
      toast.error("There have no Url and Upload Collection");
    } else if (singleColData?.uploadCollection) {
      navigate("/view-documents", { state: singleColData?.uploadCollection });
    } else if (singleColData?.url) {
      // toast.error("There have no pdf upload")
      // navigate('/view-documents', {state: singleColData?.url});
      //     <a
      //     href={singleColData?.url}
      //     target="_blank"
      //     className="photolink"
      // >
      //    {/* {item?.text} */}
      // </a>
      // <a></a>
    }

    setDocsView(true);
  };

  return (
    <>
      <section id="summary">
        <div className="nagacontainer">
          <div className="row">
            <BreadcrumbsHeader
              breadArr={[
                {
                  name: "General Collection",
                  path: "/collection",
                },
                {
                  name: singleColData?.category?.name,
                  path: `/category-details/${singleColData?.category?.slug}`,
                },
                {
                  name: summeryNameMemo,
                  path: "",
                },
                // "General Collection", singleColData?.category?.name, summeryNameMemo]}
              ]}
            />
          </div>
        </div>

        <div className="summary_body">
          <div className="summary_left">
            <div className="summary_lefttop">
              {/* <h3>
                  Road of Bones: The Siege of Kohima 1944 - the Epic Story of the Last
                  Great Stand of Empire
                </h3> */}
              <div className="summaryimg-wrapper">
                <img src={singleColData?.image} alt="img" />
              </div>

              {/* <a href="#">
                <button>
                  Buy Here
                  <i className="fa-solid fa-arrow-up-right-from-square" />
                </button>
              </a> */}

              {/* document */}

              {console.log("singleColData", singleColData)}
              {getDocumentType === "document" &&
                singleColData.uploadCollection && (
                  <>
                  {
                    colType === "books" ? <a href={singleColData?.uploadCollection}>
                    <button onClick={(e) => HamdleClick(e)}>
                      Buy Here
                      <i className="fa-solid fa-arrow-up-right-from-square" />
                    </button>
                  </a> :
                  <a href={singleColData?.uploadCollection}>
                  <button onClick={(e) => HamdleClick(e)}>
                    View Full Item
                    <i className="fa-solid fa-arrow-up-right-from-square" />
                  </button>
                </a>
                  }
                   
                  </>
                )}

              {getDocumentType === "document" && singleColData.url && (
                <>
                {
                  colType === "books" ? <a>
                  <button
                    onClick={() => openLinkInNewTab(singleColData?.url)}
                  >
                    Buy Here
                    <i className="fa-solid fa-arrow-up-right-from-square" />
                  </button>
                </a> :
                <a>
                <button
                  onClick={() => openLinkInNewTab(singleColData?.url)}
                >
                  View Full Item
                  <i className="fa-solid fa-arrow-up-right-from-square" />
                </button>
              </a>
                }
                  
                </>
              )}

              {/* audio */}
              {getDocumentType === "audios" && (
                <>
                  <a>
                    <button onClick={() => setPlayAudio(true)}>
                      Buy Here
                      <i className="fa-solid fa-arrow-up-right-from-square" />
                    </button>
                  </a>

                  {playAudio && (
                    <section className="audiopopup_area">
                      <div
                        className="wrapbox"
                        style={{
                          height: "20%",
                          width: "60%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <audio controls autoPlay loop>
                          <source
                            src={
                              singleColData?.uploadCollection
                                ? singleColData?.uploadCollection
                                : singleColData?.link
                            }
                            type="audio/mpeg"
                          />
                        </audio>

                        <button
                          className="close_btn"
                          onClick={() => setPlayAudio(false)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    </section>
                  )}
                </>
              )}

              {/* video */}
              {getDocumentType === "videos" && (
                <>
                  <a>
                    <button
                      onClick={() => {
                        if (singleColData?.uploadCollection) setVideoPlay(true);
                        //  window.open(singleColData?.link)
                        else if (singleColData?.link) setVideoLinkPlay(true);
                        else
                          toast.error(
                            "Not any uploadCollection & link is Available "
                          );
                      }}
                    >
                      Buy Here
                      <i className="fa-solid fa-arrow-up-right-from-square" />
                    </button>
                  </a>
                  {videoPlay && (
                    <section className="videopopup_area">
                      <div
                        className="wrapbox"
                        style={{ height: "70%", width: "60%" }}
                      >
                        <video controls autoPlay loop width="320" height="240">
                          <source
                            src={singleColData?.uploadCollection}
                            type="video/mp4"
                          />
                        </video>
                        <button
                          className="close_btn"
                          onClick={() => setVideoPlay(false)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    </section>
                  )}
                  {videoLinkPlay && (
                    <section className="videopopup_area">
                      <div
                        className="wrapbox"
                        style={{ height: "70%", width: "60%" }}
                      >
                        {/* <video controls autoPlay loop width="320" height="240">
                            <source src={singleColData?.uploadCollection} type="video/mp4" />
                          </video> */}
                        <div style={{ height: "100%", width: "100%" }}>
                          <ReactPlayer
                            className="react-player"
                            controls
                            width="100%"
                            height="100%"
                            playing={true}
                            url={singleColData.link}
                            config={{
                              youtube: {
                                playerVars: { showinfo: 1 },
                              },
                              facebook: {
                                appId: "12345",
                              },
                            }}
                          />
                        </div>
                        <button
                          className="close_btn"
                          onClick={() => setVideoLinkPlay(false)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    </section>
                  )}
                </>
              )}
            </div>

            <div className="summary_leftbtm">
              <div className="bottom_list">
                <div className="left_heading">
                  <p>
                    Title: <span>{singleColData?.title}</span>
                  </p>
                </div>
              </div>
              <div className="bottom_list">
                <div className="left_heading">
                  {singleColData?.author && (
                    <p>
                      Author: <span>{singleColData?.author}</span>
                    </p>
                  )}
                </div>
              </div>
              <div className="bottom_list">
                <div className="left_heading">
                  <p>
                    Date Published: <span>{singleColData?.publishDate}</span>
                  </p>
                </div>
              </div>

              {singleColData?.partner && (
                <div className="bottom_list">
                  <div className="left_heading">
                    <p>
                      Partner: <span>{singleColData?.partner}</span>
                    </p>
                  </div>
                </div>
              )}

              <div className="bottom_list">
                <div className="left_heading">
                  <p>
                    Category: <span>{singleColData?.category?.name}</span>
                  </p>
                </div>
              </div>
              {singleColData?.type === "books" ? (
                <div className="bottom_list">
                  <div className="left_heading">
                    <p>
                      SubCategory:{" "}
                      <span>
                        {singleColData?.isFiction ? "Fiction" : "Non-Fiction"}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}

              {singleColData?.url && (
                <div className="bottom_list">
                  <div className="left_heading">
                    <p>
                      URL:{" "}
                      <a
                        // href={singleColData?.url}
                        href={checkUrl(singleColData?.url)}
                        target="_blank"
                        className="photolink"
                      >
                        <span>{singleColData?.url}</span>
                      </a>
                    </p>
                  </div>
                </div>
              )}

              {singleColData?.license && (
                <div className="bottom_list">
                  <div className="left_heading">
                    <p>
                      License/Rights: <span>{singleColData?.license}</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="summary_right">
            <h3>{summeryNameMemo} Summary</h3>
            <p>
              <div
                dangerouslySetInnerHTML={{ __html: singleColData?.summery }}
              />
            </p>

            {/* <div className="right_img row row-cols-xl-4 row-cols-2">
                <figure className='learnMFig'>
                  <img src={learnM} />
                </figure>
                <div className='smallLogsMainDiv'>
                  <div className='smallLogsDiv'>
                    <figure className='smallLogos'>
                      <img src={booksL} />
                    </figure>
                    <span className='smallDocText'>Books you can read</span>
                  </div>
                  <figure className='booksAfterFig'>
                    <img src={booksG} />
                  </figure>
                </div>
                <div className='smallLogsMainDiv'>
                  <div className='smallLogsDiv'>
                    <figure className='smallLogos'>
                      <img src={docL} />
                    </figure>
                    <span className='smallDocText'>Documents(title TBC)</span>
                  </div>
                  <ul>
                    <li className='smallDocLi'><Link to='/' className='smallDocAn'>Link1</Link></li>
                    <li className='smallDocLi'><Link to='/' className='smallDocAn'>Link2</Link></li>
                    <li className='smallDocLi'><Link to='/' className='smallDocAn'>Link3</Link></li>
                  </ul>
                </div>
                <div className='smallLogsMainDiv'>
                  <div className='smallLogsDiv'>
                    <figure className='smallLogos'>
                      <img src={videoL} />
                    </figure>
                    <span className='smallDocText'>Video collection</span>
                  </div>
                  <ul>
                    <li className='smallDocLi'><Link to='/' className='smallDocAn'>Link1</Link></li>
                    <li className='smallDocLi'><Link to='/' className='smallDocAn'>Link2</Link></li>
                    <li className='smallDocLi'><Link to='/' className='smallDocAn'>Link3</Link></li>
                  </ul>
                </div>
              </div> */}
          </div>
        </div>
      </section>

      {/* {openTypeModal &&
        <TypeModal
          setOpenTypeModal={setOpenTypeModal}
          singleColData={singleColData}
        />
      } */}
    </>
  );
}
