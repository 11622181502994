import React from 'react'
import DetailsPhoto from "../../Component/DetailsPhoto";
import BreadcrumbsHeader from '../../Component/BreadcrumbsHeader'
import { useParams } from 'react-router-dom';

function moreDetails() {
  return (
    <>
      <div className='container mt-4'>
        <div className='row'>
          <BreadcrumbsHeader menu="Collections" category="History & Heritage" type="Photograph" />
        </div>
        <DetailsPhoto />
      </div>

    </>
  )
}

export default moreDetails