import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
// import e from "../../Images/purpose.jpg"
// import purpose from "../../Images/ourpurpose.jpeg"
import purpose from "../../Images/ourpurpose_pic.jpeg"
import pattern from "../../Images/pattern.jpg"
import AOS from "aos";
import "aos/dist/aos.css";


const PurposeHome = () => {

     // aos animation
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    return (
      <div>
        <div className="sian" style={{ backgroundColor: 'rgb(249, 246, 240)' }}>
          <div className="nagacontainer conFlu">
            {/* <div className="collectionTop" style={{ paddingBottom: '20px' }}>
              <div style={{width:'50%'}}></div>
              <p className='homeFea'>Home to Local Collections, Indigenous Literature, art and many more.</p>
              <figure className="ptrnFig">
                <img src={pattern} alt="" />
              </figure>
            </div> */}

            <h3 className="feco">Our Purpose</h3>
            <div className="row" data-aos="fade-up" data-aos-duration="3000">
              <div className="col-xl-5 col-lg-6 chkCol">
                <div className="img-box">
                  <img src={purpose} alt="" />
                  <p style={{ fontSize: '10px', fontFamily: 'Montserrat', marginTop: "10px", lineHeight: "18px", }}>
                  The Battle of Kohima March - July 1944: View of the Garrison Hill battlefield with the British and Japanese positions shown. Garrison Hill was the key to the British defences at Kohima.
                  </p>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6">
                <div className="fact-para">
                  <p className="fact">
                    The Digital Library of Nagaland takes immense pride in curating an extensive collection of resources
                    authored by local writers, which authentically reflects the cultural and linguistic richness of
                    Nagaland. Our community library serves as a vibrant platform that brings together readers, writers,
                    and researchers, fostering mutual learning and growth. We are deeply committed to the preservation
                    and promotion of Nagaland's literary heritage, and we achieve this by offering free access to a vast
                    array of resources, including books, journals, archives, images, and videos, all of which
                    encapsulate the distinctive stories and perspectives of our people.
                  </p>
                  <p className="fact">
                    The inception of the Digital Library of Nagaland was driven by the desire to safeguard the abundant
                    cultural heritage and literature of Nagaland and make it accessible to a global audience. Our core
                    mission revolves around establishing an online platform that not only showcases the diverse culture
                    and history of Nagaland but also provides an inclusive space for local authors and researchers to
                    share their invaluable work with the world.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className='row mt-5'>
            <div className='col readBtnDiv'>
              <figure className='ptrnFig2'>
                <img src={pattern} alt='' />
              </figure>
              <Link to="/" className='readMoreLink'>Read More..</Link>
            </div>
          </div> */}
            <div className="seemore_div"
            //  data-aos="fade-up"
            //   data-aos-duration="3000"
            >
              <Link to="/about">learn more</Link>
            </div>
          </div>
        </div>
      </div>
    );
}

export default PurposeHome
